<div class="top-nav-guide w-100 align-self-start">
    <span class="clickcross text-white text-end">
        <span class="d-block pe-1">
            
            <svg width="89" height="60" viewBox="0 0 89 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M55.9319 21.8875L56.1988 22.8512L56.1988 22.8512L55.9319 21.8875ZM57.305 22.2409C57.5771 21.7603 57.4081 21.1501 56.9275 20.878L49.0956 16.4437C48.615 16.1716 48.0049 16.3406 47.7327 16.8212C47.4606 17.3018 47.6297 17.912 48.1103 18.1841L55.0719 22.1257L51.1303 29.0873C50.8582 29.5679 51.0272 30.1781 51.5078 30.4502C51.9884 30.7223 52.5986 30.5533 52.8707 30.0727L57.305 22.2409ZM56.1988 22.8512L56.7017 22.7119L56.1678 20.7844L55.6649 20.9237L56.1988 22.8512ZM8.19054 59.2124C18.9708 41.4133 36.1447 28.4059 56.1988 22.8512L55.6649 20.9237C35.1192 26.6146 17.5244 39.9409 6.47984 58.1763L8.19054 59.2124Z" fill="#ECECEC"/>
                <path d="M68.2185 4.28085C68.1488 4.21112 68.0934 4.12833 68.0557 4.03722C68.018 3.94611 67.9985 3.84846 67.9985 3.74985C67.9985 3.65123 68.018 3.55358 68.0557 3.46247C68.0934 3.37136 68.1488 3.28858 68.2185 3.21885C68.2882 3.14912 68.371 3.0938 68.4621 3.05606C68.5532 3.01833 68.6509 2.9989 68.7495 2.9989C68.8481 2.9989 68.9457 3.01833 69.0369 3.05606C69.128 3.0938 69.2108 3.14912 69.2805 3.21885L76.9995 10.9393L84.7185 3.21885C84.7882 3.14912 84.871 3.0938 84.9621 3.05606C85.0532 3.01833 85.1509 2.9989 85.2495 2.9989C85.3481 2.9989 85.4457 3.01833 85.5369 3.05606C85.628 3.0938 85.7108 3.14912 85.7805 3.21885C85.8502 3.28858 85.9055 3.37136 85.9433 3.46247C85.981 3.55358 86.0004 3.65123 86.0004 3.74985C86.0004 3.84846 85.981 3.94611 85.9433 4.03722C85.9055 4.12833 85.8502 4.21112 85.7805 4.28085L78.06 11.9998L85.7805 19.7188C85.8502 19.7886 85.9055 19.8714 85.9433 19.9625C85.981 20.0536 86.0004 20.1512 86.0004 20.2498C86.0004 20.3485 85.981 20.4461 85.9433 20.5372C85.9055 20.6283 85.8502 20.7111 85.7805 20.7808C85.7108 20.8506 85.628 20.9059 85.5369 20.9436C85.4457 20.9814 85.3481 21.0008 85.2495 21.0008C85.1509 21.0008 85.0532 20.9814 84.9621 20.9436C84.871 20.9059 84.7882 20.8506 84.7185 20.7808L76.9995 13.0603L69.2805 20.7808C69.2108 20.8506 69.128 20.9059 69.0369 20.9436C68.9457 20.9814 68.8481 21.0008 68.7495 21.0008C68.6509 21.0008 68.5532 20.9814 68.4621 20.9436C68.371 20.9059 68.2882 20.8506 68.2185 20.7808C68.1488 20.7111 68.0934 20.6283 68.0557 20.5372C68.018 20.4461 67.9985 20.3485 67.9985 20.2498C67.9985 20.1512 68.018 20.0536 68.0557 19.9625C68.0934 19.8714 68.1488 19.7886 68.2185 19.7188L75.939 11.9998L68.2185 4.28085Z" fill="white"/>
            </svg>
    
        </span>
        <span class="d-block pt-2 col-6 text-center ms-auto">Back to <br/> dashboard page</span>
    </span>
</div>
<div class="w-100 mb-auto px-3">
    <!-- <div class="nav-middlecontent justify-content-center w-100">
        <h3 class="w-100 text-white text-center ">Navigation Guide</h3>
        <span class="w-100 d-block text-white text-center">(Tap anywhere to hide)</span>
    </div> -->
    <div class="bottom-nav-guide d-flex justify-content-between w-100 pt-5">
        <span class="swap-left text-white">
            
            <svg width="72" height="55" viewBox="0 0 72 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1036_45)">
                <path d="M39.3803 32.0968L32.297 19.8281C31.9655 19.2539 31.8756 18.5716 32.0472 17.9311C32.2189 17.2907 32.6378 16.7446 33.2121 16.4131C33.7863 16.0816 34.4687 15.9917 35.1091 16.1633C35.7496 16.3349 36.2956 16.7539 36.6271 17.3281L42.8771 28.1535" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M42.4605 27.4317L40.7938 24.5449C40.4623 23.9707 40.3725 23.2883 40.5441 22.6479C40.7157 22.0074 41.1347 21.4614 41.7089 21.1299C42.2831 20.7984 42.9655 20.7085 43.6059 20.8801C44.2464 21.0517 44.7924 21.4707 45.124 22.0449L47.2073 25.6534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M45.9573 23.4883C45.6258 22.9141 45.5359 22.2317 45.7075 21.5913C45.8791 20.9508 46.2981 20.4048 46.8723 20.0732C47.4465 19.7417 48.1289 19.6519 48.7694 19.8235C49.4098 19.9951 49.9559 20.4141 50.2874 20.9883L51.5374 23.1534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M51.1207 22.4318C50.7892 21.8576 50.6994 21.1752 50.871 20.5348C51.0426 19.8943 51.4616 19.3483 52.0358 19.0168C52.61 18.6853 53.2924 18.5954 53.9328 18.767C54.5733 18.9386 55.1193 19.3576 55.4508 19.9318L59.2008 26.427C60.5269 28.7239 60.8863 31.4534 60.1999 34.0152C59.5134 36.577 57.8374 38.7612 55.5406 40.0873L52.6538 41.7539L52.9541 41.5806C51.52 42.4089 49.9026 42.8681 48.2472 42.9169C46.5918 42.9657 44.9503 42.6026 43.4699 41.8602L42.937 41.5905C42.0875 41.1591 38.9161 39.3162 33.4207 36.0612C32.8605 35.7294 32.4518 35.1921 32.2816 34.5637C32.1114 33.9352 32.1931 33.2651 32.5093 32.696C32.846 32.0895 33.3757 31.6129 34.0142 31.3419C34.6527 31.0709 35.3635 31.021 36.0336 31.2001L39.3803 32.0969" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M26.7169 20.1638L24.4402 19.5537" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.6068 26.7702L27.1635 27.6035" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M39.7072 12.6631L40.3173 10.3864" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43.6506 16.1602L45.094 15.3268" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <path d="M29.9131 8.64053L29.9978 7.64412L29.9131 8.64053ZM8.72116 14.1272L9.2787 14.9574L9.2787 14.9574L8.72116 14.1272ZM0.70113 18.6619C0.594671 19.2038 0.947688 19.7294 1.48962 19.8359L10.3208 21.5707C10.8628 21.6772 11.3884 21.3242 11.4948 20.7822C11.6013 20.2403 11.2483 19.7147 10.7063 19.6082L2.85638 18.0661L4.39846 10.2162C4.50492 9.67425 4.15191 9.14863 3.60998 9.04217C3.06805 8.93571 2.54243 9.28873 2.43597 9.83065L0.70113 18.6619ZM37.5063 8.28191L29.9978 7.64412L29.8285 9.63694L37.337 10.2747L37.5063 8.28191ZM8.16361 13.2971L1.12483 18.0245L2.23992 19.6848L9.2787 14.9574L8.16361 13.2971ZM29.9978 7.64412C22.2885 6.98927 14.5865 8.98337 8.16361 13.2971L9.2787 14.9574C15.3238 10.8974 22.5727 9.02062 29.8285 9.63694L29.9978 7.64412Z" fill="#ECECEC"/>
                <defs>
                <clipPath id="clip0_1036_45">
                <rect width="40" height="40" fill="white" transform="translate(17 20) rotate(-30)"/>
                </clipPath>
                </defs>
                </svg>
            <span class="d-block pt-2">
                Swipe for <br/> previous
            </span>
        </span>
        
        <span class="swap-right text-white">
            <svg width="76" height="55" viewBox="0 0 76 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1036_57)">
                <path d="M32.2608 32.0968L39.3441 19.8281C39.6756 19.2539 39.7655 18.5716 39.5939 17.9311C39.4223 17.2907 39.0033 16.7446 38.4291 16.4131C37.8548 16.0816 37.1725 15.9917 36.532 16.1633C35.8916 16.3349 35.3455 16.7539 35.014 17.3281L28.764 28.1535" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.1806 27.4317L30.8473 24.5449C31.1788 23.9707 31.2686 23.2883 31.097 22.6479C30.9254 22.0074 30.5064 21.4614 29.9322 21.1299C29.358 20.7984 28.6756 20.7085 28.0352 20.8801C27.3947 21.0517 26.8487 21.4707 26.5172 22.0449L24.4338 25.6534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.6838 23.4883C26.0154 22.9141 26.1052 22.2317 25.9336 21.5913C25.762 20.9508 25.343 20.4048 24.7688 20.0732C24.1946 19.7417 23.5122 19.6519 22.8717 19.8235C22.2313 19.9951 21.6852 20.4141 21.3537 20.9883L20.1037 23.1534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.5204 22.4318C20.8519 21.8576 20.9418 21.1752 20.7701 20.5348C20.5985 19.8943 20.1795 19.3483 19.6053 19.0168C19.0311 18.6853 18.3487 18.5954 17.7083 18.767C17.0678 18.9386 16.5218 19.3576 16.1903 19.9318L12.4403 26.427C11.1142 28.7239 10.7548 31.4534 11.4413 34.0152C12.1277 36.577 13.8037 38.7612 16.1005 40.0873L18.9873 41.7539L18.687 41.5806C20.1211 42.4089 21.7385 42.8681 23.3939 42.9169C25.0493 42.9657 26.6909 42.6026 28.1712 41.8602L28.7041 41.5905C29.5536 41.1591 32.725 39.3162 38.2204 36.0612C38.7806 35.7294 39.1893 35.1921 39.3595 34.5637C39.5297 33.9352 39.448 33.2651 39.1318 32.696C38.7951 32.0895 38.2654 31.6129 37.6269 31.3419C36.9884 31.0709 36.2777 31.021 35.6075 31.2001L32.2608 32.0969" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M44.9242 20.1638L47.2009 19.5537" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43.0343 26.7702L44.4777 27.6035" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31.9339 12.6631L31.3238 10.3864" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M27.9905 16.1602L26.5471 15.3268" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <path d="M45.9389 8.64053L45.8543 7.64412L45.9389 8.64053ZM67.1309 14.1272L66.5733 14.9574L66.5733 14.9574L67.1309 14.1272ZM75.1509 18.6619C75.2574 19.2038 74.9044 19.7294 74.3624 19.8359L65.5312 21.5707C64.9893 21.6772 64.4637 21.3242 64.3572 20.7822C64.2508 20.2403 64.6038 19.7147 65.1457 19.6082L72.9957 18.0661L71.4536 10.2162C71.3471 9.67425 71.7001 9.14863 72.2421 9.04217C72.784 8.93571 73.3096 9.28873 73.4161 9.83065L75.1509 18.6619ZM38.3458 8.28191L45.8543 7.64412L46.0235 9.63694L38.5151 10.2747L38.3458 8.28191ZM67.6884 13.2971L74.7272 18.0245L73.6121 19.6848L66.5733 14.9574L67.6884 13.2971ZM45.8543 7.64412C53.5635 6.98927 61.2656 8.98337 67.6884 13.2971L66.5733 14.9574C60.5283 10.8974 53.2793 9.02062 46.0235 9.63694L45.8543 7.64412Z" fill="#ECECEC"/>
                <defs>
                <clipPath id="clip0_1036_57">
                <rect width="40" height="40" fill="white" transform="matrix(-0.866025 -0.5 -0.5 0.866025 54.6411 20)"/>
                </clipPath>
                </defs>
                </svg>
            <span class="d-block pt-2">
                Swipe for <br/> next
            </span>
        </span>
    </div>

    <div class="bottom-nav-guide  text-center w-100 pt-5">
        
            <svg width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="9" r="9" fill="#D9D9D9" fill-opacity="0.5"/>
                <g clip-path="url(#clip0_1091_166)">
                <path d="M13.6538 24.9872L13.6538 10.8206C13.6538 10.1575 13.9172 9.52163 14.386 9.05279C14.8549 8.58395 15.4908 8.32056 16.1538 8.32056C16.8168 8.32056 17.4527 8.58395 17.9216 9.05279C18.3904 9.52163 18.6538 10.1575 18.6538 10.8206V23.3206" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.6538 22.4873V19.1539C18.6538 18.4909 18.9172 17.855 19.386 17.3862C19.8549 16.9173 20.4908 16.6539 21.1538 16.6539C21.8168 16.6539 22.4527 16.9173 22.9216 17.3862C23.3904 17.855 23.6538 18.4909 23.6538 19.1539V23.3206" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23.6538 20.8206C23.6538 20.1575 23.9172 19.5216 24.386 19.0528C24.8549 18.5839 25.4908 18.3206 26.1538 18.3206C26.8168 18.3206 27.4527 18.5839 27.9216 19.0528C28.3904 19.5216 28.6538 20.1575 28.6538 20.8206V23.3206" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.6535 22.4872C28.6535 21.8241 28.9169 21.1883 29.3857 20.7194C29.8545 20.2506 30.4904 19.9872 31.1535 19.9872C31.8165 19.9872 32.4524 20.2506 32.9212 20.7194C33.3901 21.1883 33.6535 21.8241 33.6535 22.4872V29.9872C33.6535 32.6393 32.5999 35.1829 30.7245 37.0583C28.8492 38.9336 26.3056 39.9872 23.6535 39.9872H20.3201H20.6668C19.0107 39.9875 17.3804 39.5764 15.9224 38.791C14.4644 38.0056 13.2243 36.8703 12.3135 35.4872L11.9868 34.9872C11.4668 34.1888 9.6418 31.0072 6.51013 25.4405C6.19088 24.8731 6.1056 24.2035 6.27243 23.5741C6.43927 22.9447 6.84504 22.4053 7.40346 22.0705C7.99826 21.7136 8.69525 21.5657 9.38373 21.6503C10.0722 21.7348 10.7127 22.047 11.2035 22.5372L13.6535 24.9872" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.65348 8.3206L6.98682 6.65393" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.98698 14.9872H5.32031" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M23.6538 8.3206L25.3205 6.65393" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.3203 13.3206H26.987" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1091_166">
                <rect width="40" height="40" fill="white" transform="translate(0.320312 3.32056)"/>
                </clipPath>
                </defs>
                </svg>
            <span class="d-block pt-2 text-white">
                Tap to hide <br/> this screen
            </span>
    </div>
</div>
