import { Component, OnInit, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from '../services/communication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as $ from 'jquery';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { Enum } from '../helpers/enums/enum';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SapiService } from '../services/sapiservice.service';
import { RegistrationRoutingService } from '../services/registration-routing.service';
import { SessionService } from '../services/active-session-management.service';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  host: { 'class': 'd-flex flex-column h-100 product-gry ' },
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  @ViewChild('lesApprovedModal', { static: false }) lesApprovedModal: ElementRef;
  @ViewChild('finCheckAgreeModal', { static: false }) finCheckAgreeModal: ElementRef;
  @ViewChild('finCheckSubmitModal', { static: false }) finCheckSubmitModal: ElementRef;
  @ViewChild('finCheckErrorModal', { static: false }) finCheckErrorModal: ElementRef;
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  @ViewChild('callbackSuccessModel', { static: false }) callbackSuccessModel: ElementRef;
  @ViewChild('callbackErrorModel', { static: false }) callbackErrorModel: ElementRef;
  @ViewChild('lesDeclineModal', { static: false }) lesDeclineModal: ElementRef;
  @ViewChild('carInsuranceConfirmationModel', { static: false }) carInsuranceConfirmationModel: ElementRef;
  @ViewChild('carInsuranceSuccessModel', { static: false }) carInsuranceSuccessModel: ElementRef;
  @ViewChild('debtConsolidationModel', { static: false }) debtConsolidationModel: ElementRef;
  @ViewChild('monthlyExpenses', { static: false }) monthlyExpenses: ElementRef;
  @ViewChild('financeIndicator', { static: false }) financeIndicator: ElementRef;
  @ViewChild('creditLifeModel', { static: false }) creditLifeModel: ElementRef;
  @ViewChild('getMoneySection', { static: true }) getMoneySection!: ElementRef;
  @ViewChild('oobaCalculatorModel', { static: false }) oobaCalculatorModel: ElementRef;
  @ViewChild('motorVehicleCalculatorModel', { static: false }) motorVehicleCalculatorModel: ElementRef;
  @ViewChild('oobastaysavvySuccessModel', { static: false }) oobastaysavvySuccessModel: ElementRef;
  
  modalOptions: NgbModalOptions;
  errorMessage = ''
  callbackErrorMsg = ''
  callbackSuccessMsg = ''
  callback = null
  callbackPopup = null
  userData;
  env = environment;
  campaignSourceType = 8;
  callBackErrorDebt = 'Please try after 15 minutes.';
  userType = 2;
  dbc_Dcrs = 0; monthlyInstalment = 0; grossIncome = 0;
  
  activeIds: string[] = [];
  panels = [0, 1, 2, 3, 4, 5 , 6];
  dcStatus; splStatus; mobicredStatus; vehicleFinanceStatus;oobaStatus;capfinStatus;ssfStatus;
  mobicredBtnName = ''; vehicleFinanceBtnName = ''; vehicleReFinanceBtnName = ''; nedbankBtnName = '';oobaBtnName = ''; capfinBtnName =''; buttonText ='';
  vehicleFinanceCampaignSource = 0; vehicleRefinanceCampaignSource = 0;
  bestPriceForMyCarCampaignSource = Enum.LmsCampaignSourceType.BestPriceForMyCar;
  rentToBuyCampaignSource = Enum.LmsCampaignSourceType.RentToBuy;

  monthlyExpensesForm; monthlyExpensesErrorMsg = '';
  categoryType = 0; vehiclePrice = 0; maxMonthlyInstallment = 0;
  identityProductRedirect = environment.identityProductRedirect;
  truworthsRedirect = environment.truworthsProductRedirect;
  truworthsStatus; truworthsBtnName = ''; identityBtnName = '';ssfBtnName ='';
  hitOobaLead = false;

  creditLifeInsuranceDescription;
  creditLifeInsuranceDescriptionColor;
  currentPremium;
  ourInsurance;
  totalMonthlyRepaymentAmount: number;
  totalLoanAmount: number;
  IsShowCapfin=false;
  IsShowSsf = false;
  estimatedLoanAmount: number = 0;
  estimatedLoanAmountCombine:number=0;
  estimatedMonthlyRepayment: number = 0;
  estimatedMonthlyRepaymentCombine:number=0;
  validationError: string = '';
  oobaCalculatorForm; 
  oobaCombineCalculatorForm;
  validationErrorGrossIncome:string ='';
  validationErrorMonthlyExpenses:string ='';
  validationErrorNetIncome:string ='';
  validationErrorForJointTabPartner1GrossIncome:string ='';
  validationErrorForJointTabPartner1TotalExpenses:string ='';
  validationErrorForJointTabPartner1NetIncome:string ='';
  validationErrorForJointTabPartner2GrossIncome:string ='';
  validationErrorForJointTabPartner2TotalExpenses:string ='';
  validationErrorForJointTabPartner2NetIncome:string ='';
  ssfApplyRedirectUrl= environment.ssfApplyRedirectUrl;
  oobaShowDiv = false; 
  oobaJointApplicationShowDiv = false; 
  eRef: ElementRef;
CapfinProductTileId="capfinProduct"
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private modalService: NgbModal,
    private communicationService: CommunicationService,
    private userService: UserService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private sapiService: SapiService,
    private registrationRoutingService: RegistrationRoutingService,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private sessionService:SessionService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };
    this.eRef=elementRef;
  }

  ngOnInit() {
    this.sessionService.checkActiveSession();
    if (!this.oauthService.getIdentityClaims()) {
      this.router.navigate(['/register']);
    }
    this.userData = this.oauthService.getIdentityClaims();
   
    var date = new Date();
    var hour = date.getUTCHours() + 2;
    var day = date.getDay();
    var minutes = date.getUTCMinutes();
    
    if (day == 1 || day == 2 || day == 3 || day == 4) {
      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 17) {
        this.createChat();
      }
    }
    if (day == 5) {
      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 16) {
        this.createChat();
      }
    }

    // this.userService.getDebtConsolidation().subscribe((data: any) => {
    //   this.userType = data.dbcUserType;
    // }, (err: HttpErrorResponse) => {
    //   });

    this.activeIds = this.panels.map(p => "panel-" + p);

    this.getProductsQualify();

    this.monthlyExpensesForm = this.formBuilder.group({
      MonthlyExpenses: ['', [Validators.required, Validators.min(0)]],
    });

    this.getDebtConsolidation();
    this.fetchOobaData();
   // this.getCreditLife();

 
    
    this.oobaCalculatorForm = this.formBuilder.group({
      GrossMonthlyIncome: ['', [Validators.required, Validators.min(1)]],  
      NetMonthlyIncome: ['', [Validators.required, Validators.min(1)]],  
      TotalMonthlyExpenses: ['', [Validators.required, Validators.min(1)]],  
      NetSurplusIncome: [''], 
      InterestRate: ['', [Validators.required, Validators.min(0)]],
      YearsToRepay: ['', [Validators.required]]
    });


this.oobaCombineCalculatorForm = this.formBuilder.group({
  'GrossMonthlyIncomePartner1': ['', [Validators.required, Validators.min(1)]],
  'NetMonthlyIncomePartner1': ['', [Validators.required, Validators.min(1)]],
  'TotalMonthlyExpensesPartner1': ['', [Validators.required, Validators.min(1)]],
  'NetSurplusIncomePartner1': [''],
  'GrossMonthlyIncomePartner2': ['', [Validators.required, Validators.min(1)]],
  'NetMonthlyIncomePartner2': ['', [Validators.required, Validators.min(1)]],
  'TotalMonthlyExpensesPartner2': ['', [Validators.required, Validators.min(1)]],
  'NetSurplusIncomePartner2': [''],
  'InterestRate': ['', Validators.required],
  'YearsToRepay': ['20', Validators.required],
});

    
   

    this.oobaCalculatorFormControls.InterestRate.value = "13.75";
    this.oobaCombineCalculatorFormControls.InterestRate.value="13.75";
    
  }

  ngAfterViewInit() {
    setTimeout(()=>{ 
      this.checkBannerData();
  }, 1000);
    
  }


  get monthlyExpensesFormControls() { return this.monthlyExpensesForm.controls; }
  get oobaCalculatorFormControls() { return this.oobaCalculatorForm.controls; }
  get oobaCombineCalculatorFormControls(){ return this.oobaCombineCalculatorForm.controls;}
 
  

  getDebtConsolidation() {
       
    var loadUserTypeData = sessionStorage.getItem('loadUserType');   
    if (loadUserTypeData != null) {
      var data = JSON.parse(loadUserTypeData);
      this.dbc_Dcrs = data.dbC_DCRS;
      this.grossIncome = data.grossIncome;
      this.monthlyInstalment = data.monthlyInstallment;
      return;
    }


    this.userService.getDebtConsolidation().subscribe((data: any) => {
      sessionStorage.removeItem('loadUserType')
    sessionStorage.setItem('loadUserType', JSON.stringify(data));
      this.dbc_Dcrs = data.dbC_DCRS;
      this.grossIncome = data.grossIncome;
      this.monthlyInstalment = data.monthlyInstallment;

    }, (err: HttpErrorResponse) => {
      //this.modalService.dismissAll();
    });

    //this.modalService.open(this.debtConsolidationModel, this.modalOptions);
  }

  getCreditLife(){
    //this.modalService.open(this.creditLifeModel ,this.modalOptions);

  }
  creditLifeInsuraceCallSuccess(){ 
    this.transferUserToCreditLifeCalculator();
    this.userCallback(Enum.LmsCampaignSourceType.JMCreditlife);
  }
  creditLifeInsuraceCallFail(){ 
    this.userCallback(Enum.LmsCampaignSourceType.JMCreditlife);
  }

  debtConsolidationCallbackApplyNow() {
    this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationHigh;//13;
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback(this.campaignSourceType);
  }

  createChat() {
    if(!environment.hideChat)
    {
      if ((window as any).fcWidget) {
        console.log("window service__2", document.referrer);
        (window as any).fcWidget.init({
          token: this.env.freshChatToken,
          host: this.env.freshChatHost,
          tags: ["JustMoney"],
          externalId: this.userData.name,     // user’s id unique to your system
          firstName: this.userData.given_name,              // user’s first name
          lastName: this.userData.family_name
        });
      }
    }
  }

  personalLoanOffer() {
    
    this.modalService.open(this.lesApprovedModal, this.modalOptions);
    //     this.communicationService.lesQualificationDecision().subscribe((data: any) => {
   
    //   if(data.decision === 'Approve'){
    //     //Fincheck flow
    //   }
    //   // else if(data.decision === 'Decline' && data.decisionReasons[0] === 'LES: Active Application Status Decline Result'){
    //   //   this.modalService.open(this.lesDeclineModal, this.modalOptions);
    //   // }
    //   else{
    //     this.personalLoansCallback();
    //   }
    // }, (err: HttpErrorResponse) => {
    //   this.errorMessage = 'Something went wrong. Please try again later'
    // });
  }

  transferLead() {
   
    this.communicationService.transferLead().subscribe((data: any) => {
   
      if (data.responseCode === '200') {
      
        window.open(data.redirectUrl, '_blank');
      }
      else {
        this.modalService.dismissAll();
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.finCheckErrorModal, this.modalOptions);
    });
  }

  fincheckLead() {
   
    this.communicationService.transferLeadFincheck().subscribe((data: any) => {
   
      if (data.code == "200") {
        this.modalService.dismissAll();
        this.modalService.open(this.finCheckSubmitModal, this.modalOptions);
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.finCheckErrorModal, this.modalOptions);
    }
    );
  }

  debtConsolidationCallback() {
    if(this.dcStatus != null){
      if (this.dcStatus.criteria == 'no-no') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationLow;
      } else if (this.dcStatus.criteria == 'no-yes') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationHigh2;
      } else if (this.dcStatus.criteria.substring(0, 3) == 'yes') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationUnderDC;
      } else {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidation;
      }
    }    
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback(this.campaignSourceType);
    //this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
    //window.location.href = "https://www.justmoney.co.za/debt-consolidation/debt-consolidation";
  }


  

  calculatorPopUP(){
    this.validationErrorGrossIncome ='';
    this.validationErrorMonthlyExpenses ='';
    this.validationErrorNetIncome ='';
    this.validationErrorForJointTabPartner1GrossIncome ='';
    this.validationErrorForJointTabPartner1TotalExpenses ='';
    this.validationErrorForJointTabPartner1NetIncome ='';
    this.validationErrorForJointTabPartner2GrossIncome ='';
    this.validationErrorForJointTabPartner2TotalExpenses ='';
    this.validationErrorForJointTabPartner2NetIncome ='';
    this.oobaCalculatorForm.reset();
    this.oobaCombineCalculatorForm.reset();
    this.estimatedLoanAmount = 0;
    this.estimatedLoanAmountCombine=0;
    this.estimatedMonthlyRepayment = 0;
    this.estimatedMonthlyRepaymentCombine=0;
    this.oobaCalculatorFormControls.InterestRate.value = "11.75";
    this.oobaCalculatorFormControls.YearsToRepay.value = '20';
     this.oobaCombineCalculatorFormControls.InterestRate.value = "11.75";
     this.oobaCombineCalculatorFormControls.YearsToRepay.value = '20';
    this.modalService.open(this.oobaCalculatorModel,{ size: 'lg',keyboard: false,backdrop: 'static' });
    
  }
  toggleOobaCalculationHide(){
    this.oobaShowDiv = false;
  }
  toogleOobaJointCalculationHide(){
    this.oobaJointApplicationShowDiv = false; 
  }

  motorvehicleCal(){
   this.modalService.open(this.motorVehicleCalculatorModel,{ size: 'lg',keyboard: false,backdrop: 'static' });
  }
  infoShow = false;
  toggleDisplayDiv() {
    this.infoShow = !this.infoShow;
  }
  personalLoansCallback() {
    this.campaignSourceType = 9;
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }

  userCallback(campaignSource) {
  
    var payload = {
      CampaignSourceType: campaignSource
    }
    
    this.communicationService.userCallbackSource(payload).subscribe((data: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions);
    }, (err: HttpErrorResponse) => {
      this.callBackErrorDebt = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackErrorModel, this.modalOptions);
    });
  }

  openCarInsurance() {
   
    this.modalService.open(this.carInsuranceConfirmationModel, this.modalOptions);
  }

  carInsuranceCallback() {
    
    this.communicationService.transferUserToTCImporterLead().subscribe((data: any) => {
   
      if (data.errorCode === '0') {
        this.modalService.dismissAll();
        this.modalService.open(this.carInsuranceSuccessModel, this.modalOptions);
      }
      else {
        this.modalService.dismissAll();
        this.modalService.open(this.callbackErrorModel, this.modalOptions);
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.callbackErrorModel, this.modalOptions);
    })
  }

  redirectToInvestment(product) {
    this.communicationService.GetInvestmentsQueryParameters().subscribe((data: any) => {
     
      if (data !== null && data.encryptedData !== null) {
        let queryParam = '?src=' + data.source + '&cx=' + data.cx + '&product=' + product + '&wc-encode=' + data.encryptedData;
        let InvestmentProductUrl: any = data.serviceEndpoint + this.env.investmentProductUrl + queryParam
        window.location.href = InvestmentProductUrl;
      
      }

    }, (err: HttpErrorResponse) => {
      console.log(err);
    });
  }

  getProductsQualify() {

    var loadProductsQualifyData = sessionStorage.getItem('loadProductsQualify');   
    if (loadProductsQualifyData != null) {
      var data = JSON.parse(loadProductsQualifyData);
      this.BindProductQualify(data);
      return;
    }

    this.productService.getProductsQualify().subscribe((data: any) => {
      this.BindProductQualify(data);
    });
  }

  private BindProductQualify(data: any) {
    if (data != null) {
      this.dcStatus = data.dcResponse;
      this.mobicredStatus = data.mobicredResponse;
      this.splStatus = data.splResponse;
      this.vehicleFinanceStatus = data.vehicleResponse;
      this.oobaStatus = data.oobaResponse;
      this.capfinStatus = data.capfinResponse;
      this.truworthsStatus = data.truworthsResponse;
      this.getCreditLifeInsuranceQuote(data.creditLifeInsuranceQuoteResponse);
         this.ssfStatus = data.ssfResponse;

      if (this.capfinStatus != null) {
        switch (this.capfinStatus.criteria) {
          case "0-69": {
            this.IsShowCapfin=false;
            this.capfinBtnName = 'btn_CapfinLow_ProductPage';
            break;
          }
          case "70-1000": {
            this.IsShowCapfin=true;
            this.CapfinProductTileId='Capfin';     
            this.capfinBtnName = 'btn_CapfinHigh_ProductPage';
            this.checkBannerData();
            break;
          }
        }
      }
      if (this.mobicredStatus != null) {
        switch (this.mobicredStatus.criteria) {
          case "0-24": {
            this.mobicredBtnName = 'btn_MobicredVeryLow_ProductPage';
            this.nedbankBtnName = 'btn_NedbankVeryLow_ProductPage';
            break;
          }
          case "25-30": {
            this.mobicredBtnName = 'btn_MobicredLow_ProductPage';
            this.nedbankBtnName = 'btn_NedbankLow_ProductPage';
            break;
          }
          case "31-64": {
            this.mobicredBtnName = 'btn_MobicredMedium_ProductPage';
            this.nedbankBtnName = 'btn_NedbankMedium_ProductPage';
            break;
          }
          case "65-85": {
            this.mobicredBtnName = 'btn_MobicredHigh_ProductPage';
            this.nedbankBtnName = 'btn_NedbankHigh_ProductPage';
            break;
          }
          case "86-10000": {
            this.mobicredBtnName = 'btn_MobicredVeryHigh_ProductPage';
            this.nedbankBtnName = 'btn_NedbankVeryHigh_ProductPage';
            break;
          }
        }
      }

      if (this.vehicleFinanceStatus != null) {
        switch (this.vehicleFinanceStatus.criteria) {
          case "0-19": {
            this.vehicleFinanceBtnName = 'btn_VehichleFinanceLow_ProductPage';
            this.vehicleReFinanceBtnName = 'btn_VehichleRE-FinanceLow_ProductPage';
            this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceLow;
            this.vehicleRefinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldReFinanceLow;
            break;
          }
          case "20-64": {
            this.vehicleFinanceBtnName = 'btn_VehichleFinanceMed_ProductPage';
            this.vehicleReFinanceBtnName = 'btn_VehichleRE-FinanceMed_ProductPage';
            this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceMedium;
            this.vehicleRefinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldReFinanceMedium;
            break;
          }
          case "65-89": {
            this.vehicleFinanceBtnName = 'btn_VehichleFinanceHigh_ProductPage';
            this.vehicleReFinanceBtnName = 'btn_VehichleRE-FinanceHigh_ProductPage';
            this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceHigh;
            this.vehicleRefinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldReFinanceHigh;
            break;
          }
          case "90-10000": {
            this.vehicleFinanceBtnName = 'btn_VehichleFinanceVeryHigh_ProductPage';
            this.vehicleReFinanceBtnName = 'btn_VehichleRE-FinanceVeryHigh_ProductPage';
            this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceVeryHigh;
            this.vehicleRefinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldReFinanceVeryHigh;
            break;
          }
        }
      }
      if (this.oobaStatus != null) {
        switch (this.oobaStatus.criteria) {
          case "0-29": {
            this.oobaBtnName = 'btn_OobaLow_ProductPage';
            this.buttonText ='Call Me'
            this.hitOobaLead = false;
            break;
          }
          case "30-49": {
            this.oobaBtnName = 'btn_OobaMed_ProductPage';
            this.buttonText ='Speak To An Expert'
            this.hitOobaLead = true;
            break;
          }
          case "50-10000": {
            this.oobaBtnName = 'btn_OobaHigh_ProductPage';
            this.buttonText ='Speak To An Expert'
            this.hitOobaLead = true;
            break;
          }
        }
      }

      if (this.truworthsStatus != null) {
        switch (this.truworthsStatus.criteria) {
          case "0-5": {
            this.truworthsBtnName = 'btn_TruworthsLow_ProductPage';
            this.identityBtnName = 'btn_IdentityLow_ProductPage';
            break;
          }
          case "6-69": {
            this.truworthsBtnName = 'btn_TruworthsHigh_ProductPage';
            this.identityBtnName = 'btn_IdentityHigh_ProductPage';
            break;
          }
          case "70-10000": {
            this.truworthsBtnName = 'btn_TruworthsVeryHigh_ProductPage';
            this.identityBtnName = 'btn_IdentityVeryHigh_ProductPage';
            break;
          }
        }
        if(this.ssfStatus != null){
        switch(this.ssfStatus.criteria){
          case "850-10000":{
            this.IsShowSsf=true;
            this.ssfBtnName = 'btn_SsfHigh_ProductPage';
            break;
          }
          case "0-849":{
            this.ssfBtnName = 'btn_SsfLow_ProductPage';
            this.IsShowSsf=false;
            this.ssfBtnName = 'btn_SsfLow_ProductPage';
            break;
          }
          
        }
      }
    }
      
    }
  }
  
  splCallbackApplyNow() {
    if(this.splStatus != null){
      if (this.splStatus.criteria == 'Maybe-Low') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPLDeclineMedium;
      } else if (this.splStatus.criteria == 'Decline') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPLDeclineLow;
      } else {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPL;
      }
    }
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback(this.campaignSourceType);
  }

  getMonthlyExpenses() {
    this.userService.getUserVehicleFinanceAssessment().subscribe((data: any) => {
      if (data != null) {
        this.monthlyExpensesForm.patchValue({
          MonthlyExpenses: data.monthlyExpenses
        });
      }
    });
    this.modalService.open(this.monthlyExpenses, this.modalOptions);
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  saveMonthlyExpenses() {
    this.monthlyExpensesErrorMsg = '';
    if (this.monthlyExpensesForm.invalid) {
      this.monthlyExpensesForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.monthlyExpensesForm.value.MonthlyExpenses) >= 0) {
      this.monthlyExpensesForm.value.MonthlyExpenses = parseFloat(this.toNumber(this.monthlyExpensesForm.value.MonthlyExpenses));
    }

    this.userService.saveMonthlyExpenses(this.monthlyExpensesForm.value).subscribe((data: any) => {
      this.modalService.dismissAll();
      if (data != null) {
        this.categoryType = data.categoryType;
        this.vehiclePrice = Math.round(data.vehiclePrice / 10000) * 10000;
        this.maxMonthlyInstallment = Math.round(data.maxMonthlyInstallment / 500) * 500;

        this.monthlyExpensesForm.reset();
        this.modalService.open(this.financeIndicator, this.modalOptions);

      }

    },
      (err: HttpErrorResponse) => {
        this.monthlyExpensesErrorMsg = err.error;
      });
  }

  changeMonthlyExpenses() {
    if (this.monthlyExpensesForm.value.MonthlyExpenses.toString().trim() === 'R') {
      this.monthlyExpensesForm.patchValue({
        MonthlyExpenses: ''
      });
    }
  }

  leadCallback() {
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    const param = { CampaignSource: 'CreditSav_Solution', Referrer: 'Justmoney', WebSource: 'CreditSav_Solution' };
    this.communicationService.leadCallbackSource(param).subscribe((data: any) => {
      this.callbackSuccessMsg == '';
      this.callbackSuccessMsg = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions);
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Something went wrong. Please try again later';
    });
  }

  getCreditLifeInsuranceQuote(data: any) {
    
    this.ourInsurance = data?.ourInsurance;
    this.currentPremium = data?.currentPremium;
    if(data?.ourInsurance >= data?.currentPremium)
    {
         this.creditLifeInsuranceDescription = "You have a <u>LOW</u> chance of being successful";
         this.creditLifeInsuranceDescriptionColor = "#8D8D8D";
    }
    else
    {
       this.creditLifeInsuranceDescription = "You have a <u>HIGH</u> chance of being successful";
       this.creditLifeInsuranceDescriptionColor = "#82C320";
    }
   }

   transferUserToCreditLifeCalculator() {
    this.productService.UserToCreditLifeCalculator().subscribe((data: any) => {
      
    },
      (err: HttpErrorResponse) => {
        if(err.status === 409){
          console.log("Record already exists");
        }
        else{
          console.log('Error: Please try after some time');
        }
      });
  }


  sapiSignUp(){
    const param = { pageName: 'products' };
    this.sapiService.sapiSignUp(param).subscribe((data: any) => {
      if (data != null) {
         if(data.statusCode == 302 && data.returnUrl != ''){     
          window.location.href = data.returnUrl;
         }
      }
    },
      (err: HttpErrorResponse) => {
        this.monthlyExpensesErrorMsg = err.error; 
      });
  }
  checkBannerData()
  {
    var fragData = this.registrationRoutingService.getScrollData();
    if(fragData == 'getMoneySection'){
     const getMoneySection: HTMLElement = this.getMoneySection.nativeElement;
     this.scroll(getMoneySection);
    }
   this.route.queryParams.subscribe(params => {
     
    let scrollTo = params['pid'];
    if (scrollTo) {
    // scrollTo='#'+scrollTo;
    const targetElement = this.eRef.nativeElement.querySelector('#'+scrollTo);
      if (targetElement) {       
        this.scroll(targetElement);
      }
      else{
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        
      }
    }
  });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  fetchOobaData() {
    this.communicationService.getOobaPreQualificationScore().subscribe(
      (data: any) => {
        if (data && data.bondRepaymentCalculationDTO) {
          this.totalMonthlyRepaymentAmount = data.bondRepaymentCalculationDTO.totalMonthlyRepaymentAmount;
          this.totalLoanAmount = data.bondRepaymentCalculationDTO.totalLoanAmount;
        } else {
          this.totalMonthlyRepaymentAmount = null;
          this.totalLoanAmount = null;
        }
      },
      (error: any) => {
        console.error('Error fetching data:', error);
      }
    );
  }
  
  calculateOobaAffordability(): void {
    this.clearOobaCalculatorErrorMessages();
    var GrossMonthlyIncomeValue = this.oobaCalculatorForm.value.GrossMonthlyIncome == null ? 'R': this.oobaCalculatorForm.value.GrossMonthlyIncome;
    var NetMonthlyIncomeValue = this.oobaCalculatorForm.value.NetMonthlyIncome == null ? 'R': this.oobaCalculatorForm.value.NetMonthlyIncome;
    var NetSurplusIncomeValue = this.oobaCalculatorForm.value.NetSurplusIncome == null ? 'R': this.oobaCalculatorForm.value.NetSurplusIncome;
    var TotalMonthlyExpensesValue = this.oobaCalculatorForm.value.TotalMonthlyExpenses == null ? 'R': this.oobaCalculatorForm.value.TotalMonthlyExpenses;


    if (GrossMonthlyIncomeValue.toString().trim() === 'R') {
      this.oobaCalculatorForm.patchValue({
        GrossMonthlyIncome: null
      });
    }
    if (NetMonthlyIncomeValue.toString().trim() === 'R') {
      this.oobaCalculatorForm.patchValue({
        NetMonthlyIncome: null
      });
    }
    if (NetSurplusIncomeValue.toString().trim() === 'R') {
      this.oobaCalculatorForm.patchValue({
        NetSurplusIncome: null
      });
    }
    if (TotalMonthlyExpensesValue.toString().trim() === 'R') {
      this.oobaCalculatorForm.patchValue({
        TotalMonthlyExpenses: null
      });
    }

    if (this.oobaCalculatorForm.invalid) {
      this.oobaCalculatorForm.markAllAsTouched();
      return;
    }

      this.oobaCalculatorForm.patchValue({
        GrossMonthlyIncome: parseFloat(this.toNumber(this.oobaCalculatorForm.controls.GrossMonthlyIncome.value)),
        NetMonthlyIncome: parseFloat(this.toNumber(this.oobaCalculatorForm.controls.NetMonthlyIncome.value)),
        TotalMonthlyExpenses: parseFloat(this.toNumber(this.oobaCalculatorForm.controls.TotalMonthlyExpenses.value)),
        NetSurplusIncome: parseFloat(this.toNumber(this.oobaCalculatorForm.controls.NetSurplusIncome.value))
      });
  
    const isValidIncome = this.checkIncomeCriteria(this.oobaCalculatorFormControls);;
    if (isValidIncome) {
      this.communicationService.getOobaAffordabilityCalculation(this.oobaCalculatorForm.value).subscribe((data: any) => {     
        if(data.bondRepaymentCalculationDTO)
        {
          this.estimatedLoanAmount = data.qualifiedTotalLoanAmount;
          this.estimatedMonthlyRepayment = data.bondRepaymentCalculationDTO.totalMonthlyRepaymentAmount;
          this.oobaShowDiv = true;  
        }
      }),
      (error: any) => {};
    }
  }
  

  calculateCombineOobaAffordability(): void {
    this.clearOobaCalculatorErrorMessages();
    const getValueOrDefault = (value: any): number | null => value !== null && value !== undefined && value.toString().trim() !== 'R' ? parseFloat(this.toNumber(value)) : null;
  
    const patchNullIfR = (controlName: string): void => {
      const controlValue = this.oobaCombineCalculatorForm.value[controlName];
      if (controlValue && controlValue.toString().trim() === 'R') {
        this.oobaCombineCalculatorForm.patchValue({ [controlName]: null });
      }
    };
  
    const patchFormValues = (): void => {
      const controls = this.oobaCombineCalculatorForm.controls;
      this.oobaCombineCalculatorForm.patchValue({
        GrossMonthlyIncomePartner1: getValueOrDefault(controls.GrossMonthlyIncomePartner1.value),
        NetMonthlyIncomePartner1: getValueOrDefault(controls.NetMonthlyIncomePartner1.value),
        TotalMonthlyExpensesPartner1: getValueOrDefault(controls.TotalMonthlyExpensesPartner1.value),
        NetSurplusIncomePartner1: getValueOrDefault(controls.NetSurplusIncomePartner1.value),
        GrossMonthlyIncomePartner2: getValueOrDefault(controls.GrossMonthlyIncomePartner2.value),
        NetMonthlyIncomePartner2: getValueOrDefault(controls.NetMonthlyIncomePartner2.value),
        TotalMonthlyExpensesPartner2: getValueOrDefault(controls.TotalMonthlyExpensesPartner2.value),
        NetSurplusIncomePartner2: getValueOrDefault(controls.NetSurplusIncomePartner2.value)
      });
    };
  
    patchFormValues();
  
    // Patch null values if 'R' is entered
    ['GrossMonthlyIncomePartner1', 'NetMonthlyIncomePartner1', 'NetSurplusIncomePartner1', 'TotalMonthlyExpensesPartner1', 'GrossMonthlyIncomePartner2', 'NetMonthlyIncomePartner2', 'NetSurplusIncomePartner2', 'TotalMonthlyExpensesPartner2']
      .forEach(patchNullIfR);
  
    const combinedFormData = {
      GrossMonthlyIncome: this.oobaCombineCalculatorForm.value.GrossMonthlyIncomePartner1 + this.oobaCombineCalculatorForm.value.GrossMonthlyIncomePartner2,
      NetMonthlyIncome: this.oobaCombineCalculatorForm.value.NetMonthlyIncomePartner1 + this.oobaCombineCalculatorForm.value.NetMonthlyIncomePartner2,
      TotalMonthlyExpenses: this.oobaCombineCalculatorForm.value.TotalMonthlyExpensesPartner1 + this.oobaCombineCalculatorForm.value.TotalMonthlyExpensesPartner2,
      NetSurplusIncome: this.oobaCombineCalculatorForm.value.NetSurplusIncomePartner1 + this.oobaCombineCalculatorForm.value.NetSurplusIncomePartner2,
      InterestRate: this.oobaCombineCalculatorForm.value.InterestRate,
      YearsToRepay: this.oobaCombineCalculatorForm.value.YearsToRepay
    };
    const isValidIncome = this.checkJointTabIncomeCriteria(this.oobaCombineCalculatorFormControls);

    if(isValidIncome){
    this.communicationService.getOobaAffordabilityCalculation(combinedFormData)
      .subscribe((data: any) => {
        if (data.bondRepaymentCalculationDTO) {
          this.estimatedLoanAmountCombine = data.qualifiedTotalLoanAmount;
          this.estimatedMonthlyRepaymentCombine = data.bondRepaymentCalculationDTO.totalMonthlyRepaymentAmount;
          this.oobaJointApplicationShowDiv = true;
        }
      }, (error: any) => {});}
  }
   
  checkIncomeCriteria(formControls: any): boolean {

    if (formControls.GrossMonthlyIncome.value <= 0) {
      this.validationErrorGrossIncome = 'Gross income must be more than 0 to continue.';
      return false;
    } else if (formControls.TotalMonthlyExpenses.value <= 0) {
      this.validationErrorMonthlyExpenses = 'Expenses must be more than 0 to continue.';
      return false;
    } else if (formControls.NetMonthlyIncome.value - formControls.TotalMonthlyExpenses.value < 0) {
      this.validationErrorMonthlyExpenses = 'Expenses cannot be more than Net Income.';
      return false;
    }
    else if (formControls.NetMonthlyIncome.value <= 0) {
      this.validationErrorNetIncome = 'Net income must be more than 0 to continue.';
      return false;
    }
    else if (formControls.NetMonthlyIncome.value > formControls.GrossMonthlyIncome.value ) {
      this.validationErrorNetIncome = 'Gross Income must be more than Net Income.';
      return false;
    }
    return true;
  }
  
  checkOobaCalculatorIncomeCriteria(): boolean {
    return this.checkIncomeCriteria(this.oobaCalculatorFormControls);
  }
  
  checkJointTabIncomeCriteria(formControls: any): boolean {
    const grossIncome1 = formControls.GrossMonthlyIncomePartner1.value;
    const grossIncome2 = formControls.GrossMonthlyIncomePartner2.value;
    const totalExpenses1 = formControls.TotalMonthlyExpensesPartner1.value;
    const totalExpenses2 = formControls.TotalMonthlyExpensesPartner2.value;
    const netMonthlyincome1 = formControls.NetMonthlyIncomePartner1.value;
    const netMonthlyincome2 = formControls.NetMonthlyIncomePartner2.value;
    
    if (grossIncome1 <= 0) {
      this.validationErrorForJointTabPartner1GrossIncome = 'Gross income must be more than 0 to continue.';
      return false;
    } else if (grossIncome2 <= 0) {
      this.validationErrorForJointTabPartner2GrossIncome = 'Gross income must be more than 0 to continue.';
      return false;
    } else if (netMonthlyincome1 <= 0) {
      this.validationErrorForJointTabPartner1NetIncome = 'Net income must be more than 0 to continue.';
      return false;
    } else if (netMonthlyincome2 <= 0) {
      this.validationErrorForJointTabPartner2NetIncome = 'Net income must be more than 0 to continue.';
      return false;
    } else if (totalExpenses1 <= 0) {
      this.validationErrorForJointTabPartner1TotalExpenses = 'Expenses must be more than 0 to continue.';
      return false;
    }else if (totalExpenses2 <= 0) {
      this.validationErrorForJointTabPartner2TotalExpenses = 'Expenses must be more than 0 to continue.';
      return false;
    }
    else if (netMonthlyincome1 > grossIncome1) {
      this.validationErrorForJointTabPartner1NetIncome = 'Gross Income must be more than Net Income.';
      return false;
    }else if (netMonthlyincome2 > grossIncome2) {
      this.validationErrorForJointTabPartner2NetIncome = 'Gross Income must be more than Net Income.';
      return false;
    }
  
    const netIncome1 = netMonthlyincome1 - totalExpenses1;
    const netIncome2 = netMonthlyincome2 - totalExpenses2;
  
    if (netIncome1 < 0) {
      this.validationErrorForJointTabPartner1TotalExpenses = 'Expenses cannot be more than Net Income.';
      return false;
    }
  
    if (netIncome2 < 0) {
      this.validationErrorForJointTabPartner2TotalExpenses = 'Expenses cannot be more than Net Income.';
      return false;
    }
  
    return true;
  }
  
  
  
  checkOobaCombineCalculatorIncomeCriteria(): boolean {
    return this.checkJointTabIncomeCriteria(this.oobaCombineCalculatorFormControls);
  }
  
clearOobaCalculatorErrorMessages(){
  this.validationErrorForJointTabPartner1GrossIncome = '';
  this.validationErrorForJointTabPartner1TotalExpenses = '';
  this.validationErrorForJointTabPartner1NetIncome = '';
  this.validationErrorForJointTabPartner2GrossIncome = '';
  this.validationErrorForJointTabPartner2TotalExpenses = '';
  this.validationErrorForJointTabPartner2NetIncome = '';
  this.validationErrorGrossIncome ='';
  this.validationErrorMonthlyExpenses ='';
  this.validationErrorNetIncome ='';
}

generateOobaLeads(includeEstimatedLoanAmount, includeTotalLoanAmount, includeEstimatedLoanAmountCombine) {
  const buttonText = this.buttonText;

  if (buttonText === 'Speak To An Expert' && this.hitOobaLead) {
    let commonField = includeEstimatedLoanAmount ? this.estimatedLoanAmount :
                     includeTotalLoanAmount ? this.totalLoanAmount :
                     includeEstimatedLoanAmountCombine ? this.estimatedLoanAmountCombine :
                     undefined;
                     
    const criteria = this.oobaStatus?.criteria;
    const oobaStatusValue = criteria === '0-29' ? 'LOW' :
                            criteria === '30-49' ? 'MEDIUM' :
                            criteria === '50-10000' ? 'HIGH' : undefined;

    if (commonField !== undefined) {
      const oobaLeadData = { commonField, oobaStatusValue };

      this.communicationService.generateOobaLead(oobaLeadData).subscribe(
        (data) => {
          this.callbackSuccessMsg = 'One of the experts at ooba Home Loans will contact you within 24 to 48 hours.';
          this.modalService.dismissAll();
          this.modalService.open(this.oobastaysavvySuccessModel, this.modalOptions);
        },
        (err) => {
          this.callbackErrorMsg = 'Something went wrong. Please try again later';
        }
      );
    } else {
      this.callbackSuccessMsg = 'One of the experts at ooba Home Loans will contact you within 24 to 48 hours.';
      this.modalService.dismissAll();
      this.modalService.open(this.oobastaysavvySuccessModel, this.modalOptions);
    }

   

  } else if (buttonText === 'Call Me') {
    this.userCallback(Enum.LmsCampaignSourceType.OobaHomeLoanLow);
  }
}


updateNetSurplusIncome(
    formGroup: FormGroup,
    netIncomeControlName: string,
    netSurplusIncomeControlName: string,
    totalMonthlyExpensesControlName: string
  ) {
    const totalMonthlyExpenses = totalMonthlyExpensesControlName
      ? this.toNumber(formGroup.get(totalMonthlyExpensesControlName)?.value)
      : 0;
  
    const netIncome = this.toNumber(formGroup.get(netIncomeControlName)?.value);
    if (!isNaN(totalMonthlyExpenses) && !isNaN(netIncome)) {
      const netSurplusIncome = netIncome - totalMonthlyExpenses;
      formGroup.get(netSurplusIncomeControlName)?.setValue(netSurplusIncome);
    } else {
      formGroup.get(netSurplusIncomeControlName)?.setValue(null);
    }
  }
  
} 
