<div class="slide1" >
    <div class="creditInsightsdetailsCarousel">
        <owl-carousel-o  [options]="customOptions">          
            <ng-template carouselSlide *ngFor="let slide of creditInsightModel; let i = index" 
            dataHash="{{slide.categoryGroup}}">
                <div class="position-relative {{slide.uiClassName}}">
                    <app-credit-insights-details [item]="i"></app-credit-insights-details>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    
    
</div>
<ng-template #navigationPop let-modal>
            <div class="navigationPopheight py-5 px-5 d-flex w-100 align-items-center flex-wrap" aria-label="Close" (click)="modal.dismiss('Cross click')">   
           <app-navigation-popup></app-navigation-popup>
       
    </div>
    
</ng-template>