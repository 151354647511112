<div class="sliderholder carasoulheight" (window:resize)="onResize($event)">
    <header class="slider-header">
        <h6 class="text-white text-center slider-caption">
            <i class="mr-1">               
                <img *ngIf="selectedSlide.sliderIcon" class="header-icon" [src]="selectedSlide.sliderIcon" alt="Dynamic Image" width="24"
                    height="24" viewBox="0 0 24 24">
            </i>
            {{selectedSlide.sliderHeading}}
        </h6>
        <div class="backTo text-end px-3 pb-4 position-absolute">
            <a href="jsvscript:void(0)" routerLink="/portal/dashboard" fragment="appcreditinsight">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.21848 4.28085C3.14875 4.21112 3.09344 4.12833 3.0557 4.03722C3.01796 3.94611 2.99854 3.84846 2.99854 3.74985C2.99854 3.65123 3.01796 3.55358 3.0557 3.46247C3.09344 3.37136 3.14875 3.28858 3.21848 3.21885C3.28821 3.14912 3.371 3.0938 3.46211 3.05606C3.55322 3.01833 3.65087 2.9989 3.74948 2.9989C3.8481 2.9989 3.94575 3.01833 4.03686 3.05606C4.12797 3.0938 4.21075 3.14912 4.28048 3.21885L11.9995 10.9393L19.7185 3.21885C19.7882 3.14912 19.871 3.0938 19.9621 3.05606C20.0532 3.01833 20.1509 2.9989 20.2495 2.9989C20.3481 2.9989 20.4457 3.01833 20.5369 3.05606C20.628 3.0938 20.7108 3.14912 20.7805 3.21885C20.8502 3.28858 20.9055 3.37136 20.9433 3.46247C20.981 3.55358 21.0004 3.65123 21.0004 3.74985C21.0004 3.84846 20.981 3.94611 20.9433 4.03722C20.9055 4.12833 20.8502 4.21112 20.7805 4.28085L13.06 11.9998L20.7805 19.7188C20.8502 19.7886 20.9055 19.8714 20.9433 19.9625C20.981 20.0536 21.0004 20.1512 21.0004 20.2498C21.0004 20.3485 20.981 20.4461 20.9433 20.5372C20.9055 20.6283 20.8502 20.7111 20.7805 20.7808C20.7108 20.8506 20.628 20.9059 20.5369 20.9436C20.4457 20.9814 20.3481 21.0008 20.2495 21.0008C20.1509 21.0008 20.0532 20.9814 19.9621 20.9436C19.871 20.9059 19.7882 20.8506 19.7185 20.7808L11.9995 13.0603L4.28048 20.7808C4.21075 20.8506 4.12797 20.9059 4.03686 20.9436C3.94575 20.9814 3.8481 21.0008 3.74948 21.0008C3.65087 21.0008 3.55322 20.9814 3.46211 20.9436C3.371 20.9059 3.28821 20.8506 3.21848 20.7808C3.14875 20.7111 3.09344 20.6283 3.0557 20.5372C3.01796 20.4461 2.99854 20.3485 2.99854 20.2498C2.99854 20.1512 3.01796 20.0536 3.0557 19.9625C3.09344 19.8714 3.14875 19.7886 3.21848 19.7188L10.939 11.9998L3.21848 4.28085Z"
                        fill="white" />
                </svg>
            </a>
        </div>
        <div class="w-100">
            <ul class="slider-custom-pagination d-flex justify-content-between">
                <li *ngFor="let slide of creditInsightModel; let i = index" [class.white-bg]="i <categoryGroupId"
                    [class.blank-bg]="i>=categoryGroupId">
                    <a href="/portal/credit-insights-details#{{i}}"></a>
                </li>
            </ul>
        </div>
    </header>
    <section class="text-center px-3 slideybody d-flex flex-wrap">
        <div class="content-section w-100">
            <div class="text-center">
                <div class="icon-status">
                    <!---thumbs up-->
                    <!-- <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.635 83.3334H17.0834C15.7027 83.3334 14.5834 82.2142 14.5834 80.8334V40.0001C14.5834 38.6193 15.7027 37.5001 17.0834 37.5001H28.6151C31.5423 37.5001 34.2549 35.9642 35.7609 33.4542L47.0542 14.6318C49.4913 10.5704 55.2309 10.1843 58.1896 13.8828C59.5842 15.6261 60.0338 17.9432 59.3921 20.0816L55.1321 34.2817C54.6509 35.8857 55.8521 37.5001 57.5267 37.5001H76.5896C82.0842 37.5001 86.075 42.7251 84.6292 48.0259L76.6746 77.1925C75.6859 80.8184 72.393 83.3334 68.635 83.3334Z" stroke-width="5" stroke-linecap="round"/>
                        <path d="M29.1666 83.3333V37.5" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->

                    <!--thumbs down-->

                    <!-- <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M68.6352 16.6666H17.0835C15.7028 16.6666 14.5835 17.7858 14.5835 19.1666V59.9999C14.5835 61.3807 15.7028 62.4999 17.0835 62.4999H28.6152C31.5425 62.4999 34.255 64.0358 35.761 66.5458L47.0543 85.3682C49.4914 89.4296 55.231 89.8157 58.1897 86.1172C59.5843 84.3739 60.0339 82.0568 59.3922 79.9184L55.1322 65.7183C54.651 64.1143 55.8522 62.4999 57.5268 62.4999H76.5897C82.0843 62.4999 86.0752 57.2749 84.6293 51.9741L76.6747 22.8075C75.686 19.1816 72.3931 16.6666 68.6352 16.6666Z" stroke="#F25424" stroke-width="5" stroke-linecap="round"/>
                        <path d="M29.1665 16.6667V62.5" stroke="#F25424" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->

                    <!--flag-->
                    <!-- <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.3335 87.4998V66.6665V87.4998ZM33.3335 66.6665V14.9044C33.3335 12.9456 35.4839 11.7477 37.1494 12.7787L73.7756 35.4521C75.3231 36.4101 75.361 38.6481 73.8464 39.6578L33.3335 66.6665Z" stroke="#F25424" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                    <img *ngIf="selectedSlide.shortTextImage" [src]="selectedSlide.shortTextImage" alt="Dynamic Image"
                        width="100" height="100" />

                </div>
            </div>
            <div class="">
                <h1 class="content-caption mb-4 font-weight-bold">{{selectedSlide.shortTextImageCaption}}</h1>
                <p class="shortDetails">
                    <span class="font-weight-bold subcaption">{{selectedSlide.shortTextHeading}} </span> <br /><br />
                   <span innerHtml="{{selectedSlide.shortTextDescription}}"></span> 
                </p>
            </div>
        </div>
        <div class="button-section mt-auto d-flex flex-wrap w-100 justify-content-center">
            <div class="button-width" *ngIf="selectedSlide.isLearnMoreAvailable">
                <button class="btn-bd-primary rounded-pill mt-3 py-2 white-text"
                    (click)="creditcardLearnMorePopup()">Learn More</button>
            </div>
            
            
            <div class="button-width" *ngIf="selectedSlide.ctaDestination?.includes('Coach')">
                <button class="btn-bd-primary rounded-pill mt-3 py-2 white-text mt-2 trasparentbg"
                    (click)="userCallback()">{{selectedSlide.ctaText}}</button>
            </div>
            <div class="button-width" *ngIf="selectedSlide.isRedirect">
                <a class="btn-bd-primary rounded-pill mt-3 py-2 white-text mt-2 trasparentbg d-inline-block"
                href="{{selectedSlide.ctaDestination}}">{{selectedSlide.ctaText}}</a>
            </div>
            
           
        </div>
    </section>
</div>


<ng-template #creditcardLearnMorePOP let-modal>

    <div class="modal-header creditModial py-0" [ngClass]="selectedSlide.uiClassName">
        <svg width="56" height="96" viewBox="0 0 56 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7895 5.88235C11.7895 9.13109 9.15031 11.7647 5.89474 11.7647C2.63916 11.7647 0 9.13109 0 5.88235C0 2.63362 2.63916 0 5.89474 0C9.15031 0 11.7895 2.63362 11.7895 5.88235Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 20.5882C11.7895 23.837 9.15031 26.4706 5.89474 26.4706C2.63916 26.4706 0 23.837 0 20.5882C0 17.3395 2.63916 14.7059 5.89474 14.7059C9.15031 14.7059 11.7895 17.3395 11.7895 20.5882Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M26.5263 20.5882C26.5263 23.837 23.8872 26.4706 20.6316 26.4706C17.376 26.4706 14.7368 23.837 14.7368 20.5882C14.7368 17.3395 17.376 14.7059 20.6316 14.7059C23.8872 14.7059 26.5263 17.3395 26.5263 20.5882Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 35.2941C11.7895 38.5429 9.15031 41.1765 5.89474 41.1765C2.63916 41.1765 0 38.5429 0 35.2941C0 32.0454 2.63916 29.4118 5.89474 29.4118C9.15031 29.4118 11.7895 32.0454 11.7895 35.2941Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M26.5263 35.2941C26.5263 38.5429 23.8872 41.1765 20.6316 41.1765C17.376 41.1765 14.7368 38.5429 14.7368 35.2941C14.7368 32.0454 17.376 29.4118 20.6316 29.4118C23.8872 29.4118 26.5263 32.0454 26.5263 35.2941Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M41.2632 35.2941C41.2632 38.5429 38.624 41.1765 35.3684 41.1765C32.1128 41.1765 29.4737 38.5429 29.4737 35.2941C29.4737 32.0454 32.1128 29.4118 35.3684 29.4118C38.624 29.4118 41.2632 32.0454 41.2632 35.2941Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 50C11.7895 53.2487 9.15031 55.8824 5.89474 55.8824C2.63916 55.8824 0 53.2487 0 50C0 46.7513 2.63916 44.1176 5.89474 44.1176C9.15031 44.1176 11.7895 46.7513 11.7895 50Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M26.5263 50C26.5263 53.2487 23.8872 55.8824 20.6316 55.8824C17.376 55.8824 14.7368 53.2487 14.7368 50C14.7368 46.7513 17.376 44.1176 20.6316 44.1176C23.8872 44.1176 26.5263 46.7513 26.5263 50Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M41.2632 50C41.2632 53.2487 38.624 55.8824 35.3684 55.8824C32.1128 55.8824 29.4737 53.2487 29.4737 50C29.4737 46.7513 32.1128 44.1176 35.3684 44.1176C38.624 44.1176 41.2632 46.7513 41.2632 50Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M56 50C56 53.2487 53.3608 55.8824 50.1053 55.8824C46.8497 55.8824 44.2105 53.2487 44.2105 50C44.2105 46.7513 46.8497 44.1176 50.1053 44.1176C53.3608 44.1176 56 46.7513 56 50Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 64.7059C11.7895 67.9546 9.15031 70.5882 5.89474 70.5882C2.63916 70.5882 0 67.9546 0 64.7059C0 61.4571 2.63916 58.8235 5.89474 58.8235C9.15031 58.8235 11.7895 61.4571 11.7895 64.7059Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M26.5263 64.7059C26.5263 67.9546 23.8872 70.5882 20.6316 70.5882C17.376 70.5882 14.7368 67.9546 14.7368 64.7059C14.7368 61.4571 17.376 58.8235 20.6316 58.8235C23.8872 58.8235 26.5263 61.4571 26.5263 64.7059Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M41.2632 64.7059C41.2632 67.9546 38.624 70.5882 35.3684 70.5882C32.1128 70.5882 29.4737 67.9546 29.4737 64.7059C29.4737 61.4571 32.1128 58.8235 35.3684 58.8235C38.624 58.8235 41.2632 61.4571 41.2632 64.7059Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 79.4118C11.7895 82.6605 9.15031 85.2941 5.89474 85.2941C2.63916 85.2941 0 82.6605 0 79.4118C0 76.163 2.63916 73.5294 5.89474 73.5294C9.15031 73.5294 11.7895 76.163 11.7895 79.4118Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M26.5263 79.4118C26.5263 82.6605 23.8872 85.2941 20.6316 85.2941C17.376 85.2941 14.7368 82.6605 14.7368 79.4118C14.7368 76.163 17.376 73.5294 20.6316 73.5294C23.8872 73.5294 26.5263 76.163 26.5263 79.4118Z"
                fill="#ECECEC" fill-opacity="0.5" />
            <path
                d="M11.7895 94.1176C11.7895 97.3664 9.15031 100 5.89474 100C2.63916 100 0 97.3664 0 94.1176C0 90.8689 2.63916 88.2353 5.89474 88.2353C9.15031 88.2353 11.7895 90.8689 11.7895 94.1176Z"
                fill="#ECECEC" fill-opacity="0.5" />
        </svg>
        <span class="h1 text-white mb-0">More insights </span>
        <button type="button" class="popclose bg-transparent border-0" id="btn-topPopupcross-referralTerms"
            name="btn-topPopupcross-referralTerms" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.0482 10.6353C11.8586 10.4587 11.6079 10.3625 11.3488 10.3671C11.0897 10.3717 10.8426 10.4766 10.6594 10.6598C10.4761 10.8431 10.3712 11.0902 10.3666 11.3493C10.362 11.6084 10.4582 11.8591 10.6348 12.0487L14.5855 16.0007L10.6348 19.9513C10.5366 20.0429 10.4578 20.1533 10.4031 20.276C10.3485 20.3986 10.3191 20.531 10.3167 20.6653C10.3144 20.7996 10.3391 20.9329 10.3893 21.0575C10.4396 21.182 10.5145 21.2951 10.6095 21.3901C10.7044 21.485 10.8175 21.5599 10.9421 21.6102C11.0666 21.6605 11.1999 21.6852 11.3342 21.6828C11.4685 21.6804 11.6009 21.651 11.7236 21.5964C11.8462 21.5417 11.9566 21.4629 12.0482 21.3647L16.0002 17.414L19.9508 21.366C20.1394 21.5483 20.3919 21.6492 20.6541 21.647C20.9163 21.6449 21.1672 21.5398 21.3527 21.3545C21.5382 21.1692 21.6435 20.9184 21.6459 20.6562C21.6483 20.394 21.5476 20.1414 21.3655 19.9527L17.4148 16.0007L21.3655 12.0487C21.4638 11.9571 21.5426 11.8467 21.5972 11.7241C21.6519 11.6014 21.6813 11.469 21.6836 11.3347C21.686 11.2004 21.6613 11.0671 21.611 10.9425C21.5607 10.818 21.4859 10.7049 21.3909 10.61C21.2959 10.515 21.1828 10.4401 21.0583 10.3898C20.9338 10.3395 20.8004 10.3148 20.6662 10.3172C20.5319 10.3196 20.3995 10.349 20.2768 10.4036C20.1541 10.4583 20.0437 10.5371 19.9522 10.6353L16.0002 14.586L12.0482 10.6353Z"
                    fill="white" />
                <path
                    d="M16.0002 1.33398C24.1002 1.33398 30.6668 7.90065 30.6668 16.0007C30.6668 24.1007 24.1002 30.6673 16.0002 30.6673C7.90016 30.6673 1.3335 24.1007 1.3335 16.0007C1.3335 7.90065 7.90016 1.33398 16.0002 1.33398ZM3.3335 16.0007C3.3335 19.3601 4.66802 22.5819 7.04348 24.9573C9.41894 27.3328 12.6408 28.6673 16.0002 28.6673C19.3596 28.6673 22.5814 27.3328 24.9568 24.9573C27.3323 22.5819 28.6668 19.3601 28.6668 16.0007C28.6668 12.6412 27.3323 9.41943 24.9568 7.04397C22.5814 4.6685 19.3596 3.33398 16.0002 3.33398C12.6408 3.33398 9.41894 4.6685 7.04348 7.04397C4.66802 9.41943 3.3335 12.6412 3.3335 16.0007Z"
                    fill="white" />
            </svg>
        </button>
    </div>
    <div class="modal-body modalcontent" innerHtml=' {{selectedSlide.longText}}'>
       
    </div>
    <div class="modal-footer button-section justify-content-center" *ngIf="selectedSlide.isCtaAvailable">
        <div class="w-100 text-center justify-content-center d-flex" *ngIf="selectedSlide.isRedirect">
            <a class="btn-bd-primary rounded-pill mt-3 py-2 white-text mt-2 trasparentbg" 
            href="{{selectedSlide.ctaDestination}}" [ngClass]="selectedSlide.uiClassName"
                (click)="modal.dismiss('Cross click')">{{selectedSlide.ctaText}}</a>            
        </div>     
        <div class="w-100 text-center justify-content-center d-flex" *ngIf="selectedSlide.ctaDestination?.includes('Coach')">
            <button class="btn-bd-primary rounded-pill mt-3 py-2 white-text mt-2 trasparentbg" (click)="userCallback()"
                [ngClass]="selectedSlide.uiClassName"
                (click)="modal.dismiss('Cross click')">{{selectedSlide.ctaText}}</button>
        </div>
  </div>
</ng-template>

<ng-template #callbackSuccessModel let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Savvy move!</h4>
        <button type="button" id="callbackSuccessModelClosed" class="close" data-dismiss="modal"
            name="callbackSuccessModelClosed" isCloseButton="true" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" class="alert alert-success" role="alert">
        <p>A JustMoney coach will give you a call to assist you with your application.</p>
    </div>
</ng-template>