import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditInsightService {

  env = environment;
  constructor(private http: HttpClient) { }


  GetCreditInsightDetails() {
    return this.http.post(this.env.webApiUrl + 'api/CreditInsight/GetCreditInsightDetails',null);
  }
}