import { Directive, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[autoIdButtons]'
})
export class AutoIdDirective implements AfterViewChecked {
  private buttonsAssigned = false;
  private currentPage = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewChecked() {
    this.assignDynamicId();
  }

  public assignDynamicId() {
    const url = window.location.href;
    if (this.currentPage !== url) {
      setTimeout(() => {
        this.assignIdsToElements('button');
        this.assignIdsToElements('a');
        this.assignIdsToElements('input[type="text"]');
        this.assignIdsToElements('input[type="checkbox"]');
        this.assignIdsToElements('input[type="radio"]');
        this.buttonsAssigned = true;
        this.currentPage = url;
      }, 4000);
    }
  }

  private assignIdsToElements(selector: string) {
    const componentElement = this.el.nativeElement;
    const pageName = window.location.href.split('/').pop();
    const elements = componentElement.querySelectorAll(selector);
  
    const identifierCounts: { [key: string]: number } = {};
  
    elements.forEach((element: HTMLElement) => {
      if (!element.id) {
        let elementType = '';
  
        if (selector === 'button') {
          elementType = 'dButton';
        } else if (selector === 'a') {
          elementType = 'dAnchor';
        } else if (selector === 'input[type="text"]') {
          elementType = 'dInputText';
        } else if (selector === 'input[type="checkbox"]') {
          elementType = 'dCheckbox';
        } else if (selector === 'input[type="radio"]') {
          elementType = 'dRadio';
        } else if (selector === 'input[type="range"]') {
          elementType = 'dSlider';
        } else {
          elementType = 'dElement';
        }
        
        let baseId = `${elementType}-${pageName}`;
  
        if (element.getAttribute('name')!=null) {        
          baseId = `${element.getAttribute('name').replace(/ +/g, '_')}`;
        }
        else if(element.getAttribute('formControlName')!=null)
        {
          baseId = `${element.getAttribute('formControlName').replace(/ +/g, '_')}`;
        }
        else if(element.innerText !=null) { 
          baseId = `${elementType}-${pageName}-${element.innerText.replace(/ +/g, '_')}`;        
      }
        baseId = baseId.replace(/[^a-zA-Z0-9-_]/g, '');
        const identifierCount = identifierCounts[baseId] || 0;
        const dynamicId = identifierCount > 0 ? `${baseId}-${identifierCount + 1}` : `${baseId}`;
  
        identifierCounts[baseId] = identifierCount + 1;
  
        this.renderer.setAttribute(element, 'id', dynamicId);
        
      }
    });
  }
  
  
}
