<div class="top-nav-guide w-100 align-self-start">
    <span class="clickcross text-white text-end">
        <span class="d-block">
            <svg width="52" height="44" viewBox="0 0 52 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M49.9319 5.88745L50.1988 6.85116L50.1988 6.85117L49.9319 5.88745ZM51.305 6.24086C51.5771 5.76026 51.4081 5.15007 50.9275 4.87796L43.0956 0.443692C42.615 0.171581 42.0049 0.340596 41.7327 0.821196C41.4606 1.30179 41.6297 1.91199 42.1103 2.18409L49.0719 6.12566L45.1303 13.0873C44.8582 13.5679 45.0272 14.1781 45.5078 14.4502C45.9884 14.7223 46.5986 14.5533 46.8707 14.0727L51.305 6.24086ZM50.1988 6.85117L50.7017 6.71188L50.1678 4.78444L49.6649 4.92373L50.1988 6.85117ZM2.19054 43.2124C12.9708 25.4133 30.1447 12.4059 50.1988 6.85116L49.665 4.92374C29.1192 10.6146 11.5244 23.9409 0.479843 42.1763L2.19054 43.2124Z" fill="#ECECEC"/>
                </svg>
        </span>
        <span class="d-block pt-2">Exit summary</span>
    </span>
</div>
<div class="w-100 mb-auto">
    <div class="nav-middlecontent justify-content-center w-100">
        <h3 class="w-100 text-white text-center ">Navigation Guide</h3>
        <span class="w-100 d-block text-white text-center">(Tap anywhere to hide)</span>
    </div>
    <div class="bottom-nav-guide d-flex justify-content-between w-100 pt-5">
        <span class="swap-left text-white">
            
            <svg width="72" height="55" viewBox="0 0 72 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1036_45)">
                <path d="M39.3803 32.0968L32.297 19.8281C31.9655 19.2539 31.8756 18.5716 32.0472 17.9311C32.2189 17.2907 32.6378 16.7446 33.2121 16.4131C33.7863 16.0816 34.4687 15.9917 35.1091 16.1633C35.7496 16.3349 36.2956 16.7539 36.6271 17.3281L42.8771 28.1535" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M42.4605 27.4317L40.7938 24.5449C40.4623 23.9707 40.3725 23.2883 40.5441 22.6479C40.7157 22.0074 41.1347 21.4614 41.7089 21.1299C42.2831 20.7984 42.9655 20.7085 43.6059 20.8801C44.2464 21.0517 44.7924 21.4707 45.124 22.0449L47.2073 25.6534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M45.9573 23.4883C45.6258 22.9141 45.5359 22.2317 45.7075 21.5913C45.8791 20.9508 46.2981 20.4048 46.8723 20.0732C47.4465 19.7417 48.1289 19.6519 48.7694 19.8235C49.4098 19.9951 49.9559 20.4141 50.2874 20.9883L51.5374 23.1534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M51.1207 22.4318C50.7892 21.8576 50.6994 21.1752 50.871 20.5348C51.0426 19.8943 51.4616 19.3483 52.0358 19.0168C52.61 18.6853 53.2924 18.5954 53.9328 18.767C54.5733 18.9386 55.1193 19.3576 55.4508 19.9318L59.2008 26.427C60.5269 28.7239 60.8863 31.4534 60.1999 34.0152C59.5134 36.577 57.8374 38.7612 55.5406 40.0873L52.6538 41.7539L52.9541 41.5806C51.52 42.4089 49.9026 42.8681 48.2472 42.9169C46.5918 42.9657 44.9503 42.6026 43.4699 41.8602L42.937 41.5905C42.0875 41.1591 38.9161 39.3162 33.4207 36.0612C32.8605 35.7294 32.4518 35.1921 32.2816 34.5637C32.1114 33.9352 32.1931 33.2651 32.5093 32.696C32.846 32.0895 33.3757 31.6129 34.0142 31.3419C34.6527 31.0709 35.3635 31.021 36.0336 31.2001L39.3803 32.0969" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M26.7169 20.1638L24.4402 19.5537" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.6068 26.7702L27.1635 27.6035" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M39.7072 12.6631L40.3173 10.3864" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43.6506 16.1602L45.094 15.3268" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <path d="M29.9131 8.64053L29.9978 7.64412L29.9131 8.64053ZM8.72116 14.1272L9.2787 14.9574L9.2787 14.9574L8.72116 14.1272ZM0.70113 18.6619C0.594671 19.2038 0.947688 19.7294 1.48962 19.8359L10.3208 21.5707C10.8628 21.6772 11.3884 21.3242 11.4948 20.7822C11.6013 20.2403 11.2483 19.7147 10.7063 19.6082L2.85638 18.0661L4.39846 10.2162C4.50492 9.67425 4.15191 9.14863 3.60998 9.04217C3.06805 8.93571 2.54243 9.28873 2.43597 9.83065L0.70113 18.6619ZM37.5063 8.28191L29.9978 7.64412L29.8285 9.63694L37.337 10.2747L37.5063 8.28191ZM8.16361 13.2971L1.12483 18.0245L2.23992 19.6848L9.2787 14.9574L8.16361 13.2971ZM29.9978 7.64412C22.2885 6.98927 14.5865 8.98337 8.16361 13.2971L9.2787 14.9574C15.3238 10.8974 22.5727 9.02062 29.8285 9.63694L29.9978 7.64412Z" fill="#ECECEC"/>
                <defs>
                <clipPath id="clip0_1036_45">
                <rect width="40" height="40" fill="white" transform="translate(17 20) rotate(-30)"/>
                </clipPath>
                </defs>
                </svg>
            <span class="d-block pt-2">
                Swipe Left <br/> to go to previous
            </span>
        </span>
        <span class="swap-right text-white">
            <svg width="76" height="55" viewBox="0 0 76 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1036_57)">
                <path d="M32.2608 32.0968L39.3441 19.8281C39.6756 19.2539 39.7655 18.5716 39.5939 17.9311C39.4223 17.2907 39.0033 16.7446 38.4291 16.4131C37.8548 16.0816 37.1725 15.9917 36.532 16.1633C35.8916 16.3349 35.3455 16.7539 35.014 17.3281L28.764 28.1535" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.1806 27.4317L30.8473 24.5449C31.1788 23.9707 31.2686 23.2883 31.097 22.6479C30.9254 22.0074 30.5064 21.4614 29.9322 21.1299C29.358 20.7984 28.6756 20.7085 28.0352 20.8801C27.3947 21.0517 26.8487 21.4707 26.5172 22.0449L24.4338 25.6534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.6838 23.4883C26.0154 22.9141 26.1052 22.2317 25.9336 21.5913C25.762 20.9508 25.343 20.4048 24.7688 20.0732C24.1946 19.7417 23.5122 19.6519 22.8717 19.8235C22.2313 19.9951 21.6852 20.4141 21.3537 20.9883L20.1037 23.1534" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.5204 22.4318C20.8519 21.8576 20.9418 21.1752 20.7701 20.5348C20.5985 19.8943 20.1795 19.3483 19.6053 19.0168C19.0311 18.6853 18.3487 18.5954 17.7083 18.767C17.0678 18.9386 16.5218 19.3576 16.1903 19.9318L12.4403 26.427C11.1142 28.7239 10.7548 31.4534 11.4413 34.0152C12.1277 36.577 13.8037 38.7612 16.1005 40.0873L18.9873 41.7539L18.687 41.5806C20.1211 42.4089 21.7385 42.8681 23.3939 42.9169C25.0493 42.9657 26.6909 42.6026 28.1712 41.8602L28.7041 41.5905C29.5536 41.1591 32.725 39.3162 38.2204 36.0612C38.7806 35.7294 39.1893 35.1921 39.3595 34.5637C39.5297 33.9352 39.448 33.2651 39.1318 32.696C38.7951 32.0895 38.2654 31.6129 37.6269 31.3419C36.9884 31.0709 36.2777 31.021 35.6075 31.2001L32.2608 32.0969" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M44.9242 20.1638L47.2009 19.5537" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M43.0343 26.7702L44.4777 27.6035" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31.9339 12.6631L31.3238 10.3864" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M27.9905 16.1602L26.5471 15.3268" stroke="#ECECEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <path d="M45.9389 8.64053L45.8543 7.64412L45.9389 8.64053ZM67.1309 14.1272L66.5733 14.9574L66.5733 14.9574L67.1309 14.1272ZM75.1509 18.6619C75.2574 19.2038 74.9044 19.7294 74.3624 19.8359L65.5312 21.5707C64.9893 21.6772 64.4637 21.3242 64.3572 20.7822C64.2508 20.2403 64.6038 19.7147 65.1457 19.6082L72.9957 18.0661L71.4536 10.2162C71.3471 9.67425 71.7001 9.14863 72.2421 9.04217C72.784 8.93571 73.3096 9.28873 73.4161 9.83065L75.1509 18.6619ZM38.3458 8.28191L45.8543 7.64412L46.0235 9.63694L38.5151 10.2747L38.3458 8.28191ZM67.6884 13.2971L74.7272 18.0245L73.6121 19.6848L66.5733 14.9574L67.6884 13.2971ZM45.8543 7.64412C53.5635 6.98927 61.2656 8.98337 67.6884 13.2971L66.5733 14.9574C60.5283 10.8974 53.2793 9.02062 46.0235 9.63694L45.8543 7.64412Z" fill="#ECECEC"/>
                <defs>
                <clipPath id="clip0_1036_57">
                <rect width="40" height="40" fill="white" transform="matrix(-0.866025 -0.5 -0.5 0.866025 54.6411 20)"/>
                </clipPath>
                </defs>
                </svg>
            <span class="d-block pt-2">
                Swipe Right <br/> to go to next
            </span>
        </span>
    </div>
</div>
