import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-credit-insights-details-slider',
  templateUrl: './credit-insights-details-slider.component.html',
  styleUrls: ['./credit-insights-details-slider.component.css']
})
export class CreditInsightsDetailsSliderComponent implements OnInit {
  @ViewChild('navigationPop', { static: true }) navigationPop: ElementRef;
  modalOptions: NgbModalOptions;
  creditInsightModel: any;
  startIndex: number = 5;
  constructor(private modalService: NgbModal, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.startIndex = params['cg'];
    });
    const storedData = sessionStorage.getItem('creditInsightModel');
    if (storedData) {
      this.creditInsightModel = JSON.parse(storedData);
    }
    this.modalOptions = {
      backdrop: 'static',
      //backdropClass: 'bg-transparent',
      keyboard: false,
      windowClass: 'navigation-popup',
      centered: true,
      fullscreen: true,
      scrollable: true
    };
    this.modalService.open(this.navigationPop, this.modalOptions);
    this.carouselNormalization();

  }
  carouselNormalization() {
    var windowh = $(window).height(),
    desiredHeight = windowh;
    $('.navigationPopheight').css("min-height", desiredHeight + 'px');
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 900,
    navText: ['', ''],
    startPosition: 'URLHash',
    nav: false,
    autoplay: false,
    autoplayHoverPause: false,
    margin:8,
    stagePadding: 0,
    smartSpeed:450,
    responsiveRefreshRate : 1000,
    autoHeight:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items:1
      },
      940: {
        items:1
      }
    },
   
   
  }

}
