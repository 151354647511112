import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { UserOneSignalSubscriptionService } from './userOneSignalSubscription.service';
declare const OneSignal: any;
@Injectable({
  providedIn: 'root'
})
export class UserOneSignalGetSubscriptionIdService {

  constructor(private userOneSignalSubscriptionService :UserOneSignalSubscriptionService) {}
  async getAndSendSubscriptionId() {
    try {
      const isAlreadySent = localStorage.getItem('sentOneSignalIdAlreadyDone');
      if (isAlreadySent === 'true') {
        return;
      }

      const subscriptionId = await OneSignal.User.PushSubscription.id;

      const data = { subscriptionId: subscriptionId };
      this.userOneSignalSubscriptionService.SendOneSignalSubscriptionId(data).subscribe(
          (isSubscriptionIdAccepted) => {
              if (isSubscriptionIdAccepted === true) {
                  localStorage.setItem('sentOneSignalIdAlreadyDone', 'true');
              } else {
                  console.warn('Subscription ID not accepted by the server.');
              }
          },
          error => {
              console.error('Error sending Subscription ID:', error);
          }
      );
      }
     catch (error) {
      console.error('Error retrieving OneSignal subscription ID:', error);
    }
  }
}