import { Component, OnInit, ElementRef, ViewChild, Input, HostListener } from '@angular/core';
import * as $ from 'jquery';
import { NgbModal, NgbModalOptions ,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Enum } from 'src/app/helpers/enums/enum';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-credit-insights-details',
  templateUrl: './credit-insights-details.component.html',
  styleUrls: ['./credit-insights-details.component.css']
})
export class CreditInsightsDetailsComponent implements OnInit {
  private modalRef!: NgbModalRef | null;
  private scrollPosition = 0;
  @Input() item = '';
  @ViewChild('creditcardLearnMorePOP', { static: false }) creditcardLearnMorePOP: ElementRef;
  @ViewChild('callbackSuccessModel', { static: false }) callbackSuccessModel: ElementRef;
  
  modalOptions: NgbModalOptions;
  modalOptionsCallback: NgbModalOptions;
  creditInsightModel: any[] = [];
  categoryGroupId: number = 1;
  ctaDestination: any;
  productTile = '';
  routeToLink = '';

  selectedSlide: any;
  campaignSource = 0;
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private communicationService: CommunicationService) {

  }
  

  ngOnInit(): void {

    this.carouselNormalization();
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'touch',
      keyboard: false,
      windowClass: 'custom-class',
      centered: true,
      fullscreen : false,
      scrollable: true,
      
    };
    this.modalOptionsCallback = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      fullscreen : false,
      scrollable: true,
    }

    this.loadCreditInsightDetails(this.item);
    this.getScreenSize();

  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    var width = window.innerWidth;  
    if (width > 767) {
      window.location.href = '/portal/dashboard'         
    }
  }
  onResize(_evt: any) {
    this.carouselNormalization();
    this.getScreenSize();
  }
  carouselNormalization() {
    var windowh = $(window).height(),
      desiredHeight = windowh;
    $('.slideybody').css("min-height", desiredHeight + 'px');
    $('.slideybody').css("background-size", desiredHeight + 'px');
  }

  creditcardLearnMorePopup() {
    this.scrollPosition = window.scrollY;
    this.modalRef = this.modalService.open(this.creditcardLearnMorePOP, this.modalOptions)
    
    document.body.classList.add('no-scroll');
    this.modalRef.result.finally(() => {
      document.body.classList.remove('no-scroll');
      document.body.style.top = '';
      window.scrollTo(0, this.scrollPosition); 
      this.modalRef = null;
      
    });
    return this.modalRef;
  }
  loadCreditInsightDetails(index: string): void {
    const storedData = sessionStorage.getItem('creditInsightModel');
    if (storedData) {
      this.creditInsightModel = JSON.parse(storedData);
      this.selectedSlide = this.creditInsightModel[index];
      this.categoryGroupId = this.selectedSlide.categoryGroup;
    }
  }

  userCallback() {
    this.retriveCampaignSource();
    var payload = {
      CampaignSourceType: this.campaignSource
    }

    this.communicationService.userCallbackSource(payload).subscribe((data: any) => {
      this.modalService.open(this.callbackSuccessModel, this.modalOptionsCallback);
    }, (error) => {
      console.error(error);
    });
  }
  retriveCampaignSource() {
    if (this.selectedSlide.ctaDestination.includes('CoachSecured')) {
      this.campaignSource = Enum.LmsCampaignSourceType.Insights_Secured_Credit;
    } else if (this.selectedSlide.ctaDestination.includes('CoachPL')) {
      this.campaignSource = Enum.LmsCampaignSourceType.Insights_Personal_Loan_High;
    }
  }
}