<section class="bg-white pb-4 pt-4 pt-md-5 b5containeradjust d-block d-md-none" id="appcreditinsight"
*ngIf="creditInsightModel!=null && creditInsightModel?.length>0"
>
    <div class="">

        <div class="col-12">
            <h3 class="fw-bold text-start"> <i> <img src="../../assets/images/stars.png" alt="" /> </i> Improve Your
                Credit Score</h3>
            <p>Personalized insights to improve your credit score.</p>
            <div class="creditInsightsCarousel py-3">
                <owl-carousel-o (window:resize)="onResize($event)" [options]="customOptions">
                    <ng-template carouselSlide [id]="slide.category"
                        *ngFor="let slide of creditInsightModel; let i = index">
                        <a class="d-flex flex-wrap w-100" href="javascript:void(0)" 
                        href="/portal/credit-insight#{{slide.categoryGroup}}"  id="btnCreditScore-{{slide.category}}">
                            <img src="{{slide.dashboardImage}}" alt="{{slide.category}}"
                                id="imgCreditScore-{{slide.category}}" />
                        </a>
                    </ng-template>
                </owl-carousel-o>

            </div>
        </div>
    </div>
</section>