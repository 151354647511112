import { Routes } from '@angular/router';
import { TermsComponent } from './terms/terms.component';
import {PolicyComponent}  from './policy/policy.component';
import { ProductsComponent } from './products/products.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AccountComponent } from './account/account.component';
import { SettingComponent } from './setting/setting.component';
import { LoginComponent } from './login/login.component';
import { NewsComponent } from './news/news.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { InvestmentsComponent } from './investments/investments.component';
import { SecurityQuestionComponent } from './security-question/security-question.component';
import { CarsRegistrationComponent } from './registration/cars-registration/cars-registration.component';
import { RegisterStepTwoComponent } from './registration/register-step-two/register-step-two.component'; 
import { CellNumberUpdateComponent } from './cell-number-update/cell-number-update.component'; 
import { WingfieldRegistrationComponent } from './registration/wingfield-registration/wingfield-registration.component';
import { BluRegistrationComponent } from './registration/blu-registration/blu-registration.component';
import { CreditInsightsDetailsSliderComponent } from './credit-insights-slider-ui/credit-insights-details-slider/credit-insights-details-slider.component';

export const appRoutes: Routes = [
  {
    path: 'portal',
    children: [
      {
        path: 'credit-insights-details', component: CreditInsightsDetailsSliderComponent
      },
      {
        path: 'credit-insight', component: CreditInsightsDetailsSliderComponent
      }
    ]
  },
  {
    path: 'portal', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', component: DashboardComponent
      },
      {
        path: 'profile', component: ProfileComponent
      },
      {
        path: 'setting', component: SettingComponent
      },
      {
        path: 'solutions', component: ProductsComponent
      },
      {
        path: 'account', component: AccountComponent
      },
      {
        path: 'news', component: NewsComponent
      },
      {
        path: 'news-details/:id', component: NewsDetailsComponent
      }
      ,
      // {
      //   path: 'investments', component: InvestmentsComponent
      // },
      {
        path: 'updatenumber', component: CellNumberUpdateComponent
      }
      
        
    ]
  },
 
  {
    path: 'register', loadChildren:()=>import('./register/register.module').then(m=>m.RegisterModule)
  },
  {
    path: 'terms', component: TermsComponent
  },
  {
    path: 'forgot', component: ForgotComponent
  },
  {
    path: 'policy', component: PolicyComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  { path: '', redirectTo: '/register', pathMatch: 'full' },
  {
    path: 'security-question', component: SecurityQuestionComponent
  },
  {
    path: 'register-step-two', component: RegisterStepTwoComponent
  },
  {
    path: 'cars-registration', component: CarsRegistrationComponent
  } ,
  {
    path: 'wingfield', component: WingfieldRegistrationComponent
  } ,
  {
    path: 'blu', component: BluRegistrationComponent
  } 
];
