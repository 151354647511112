import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse, JsonpInterceptor } from '@angular/common/http';
import { DashboardService } from '../services/dashboard.service';
import { Enum } from '../helpers/enums/enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommunicationService } from '../services/communication.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PlatformLocation } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import * as $ from 'jquery';
import { Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Chart } from 'node_modules/chart.js';
import { WelcomeTextService } from '../services/welcome-text.service';
import { ProductService } from '../services/product.service';
import { SapiService } from '../services/sapiservice.service';
import { RegistrationRoutingService } from '../services/registration-routing.service';
import { TrackingDirective } from '../helpers/directive/tracking.directive';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsService } from '../services/appinsights.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SessionService } from '../services/active-session-management.service';
import { CreditInsightService } from '../services/credit-insight.service';
import { UserOneSignalGetSubscriptionIdService } from '../services/userOneSignalGetSubscriptionId.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  host: { 'class': 'd-flex flex-column h-100 register-bg' },
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .my-custom-class .tooltip-inner {
      background-color: #3d4a5a;
      font-size: 13px;
      line-height:15px;
      padding:5px 8px 8px;
      border-color:#3d4a5a;
      width:80px;
    }
    .my-custom-class .arrow::before {
      border-top-color: #3d4a5a;
    }
  `]
})
export class DashboardComponent implements OnInit {
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  @ViewChild('captureIncomeModel', { static: false }) captureIncomeModel: ElementRef;
  // product page impleamentation----
  @ViewChild('lesApprovedModal', { static: false }) lesApprovedModal: ElementRef;
  @ViewChild('finCheckAgreeModal', { static: false }) finCheckAgreeModal: ElementRef;
  @ViewChild('finCheckSubmitModal', { static: false }) finCheckSubmitModal: ElementRef;
  @ViewChild('finCheckErrorModal', { static: false }) finCheckErrorModal: ElementRef;
  @ViewChild('callbackSuccessModel', { static: false }) callbackSuccessModel: ElementRef;
  @ViewChild('callbackErrorModel', { static: false }) callbackErrorModel: ElementRef;
  @ViewChild('lesDeclineModal', { static: false }) lesDeclineModal: ElementRef;
  @ViewChild('carInsuranceConfirmationModel', { static: false }) carInsuranceConfirmationModel: ElementRef;
  @ViewChild('carInsuranceSuccessModel', { static: false }) carInsuranceSuccessModel: ElementRef;
  @ViewChild('welcomeModel', { static: false }) welcomeModel: ElementRef;
  @ViewChild('debtConsolidationModel', { static: false }) debtConsolidationModel: ElementRef;
  @ViewChild('monthlyExpenses', { static: false }) monthlyExpenses: ElementRef;
  @ViewChild('financeIndicator', { static: false }) financeIndicator: ElementRef;
  @ViewChild('updateIncomeModel', { static: false }) updateIncomeModel: ElementRef;
  //--------------------------------
  env = environment;
  userData;
  creditHistory: any = {}; creditHealthInfo: any = {}; creditHealthInfoUiTextElement: any = {};
  creditInsightData: any = {};
  IsCreditInsightFetched:boolean=false;
  ChartId: string[];
  creditHistoryMonths; creditHistoryMonth = '';
  scoreStatus = ''; scoreProgress: any = 0; showMoreAccounts = false; scoreAgeProgress: any = 0; scoreColor = '';
  strokeUrl = 'url(' + window.location.href.substring(0, window.location.href.indexOf('#')) + '#Pattern)';
  modalOptions: NgbModalOptions;
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  captureIncomeForm;
  captureIncomeErrorMsg = '';
  creditReportYear; creditReportMonth;
  userType = 2;
  isShowA = true; isShowB = false; isShowC = false; isShowD = false; isShowE = false;
  openById = {};
  isDataAvailable = true;
  scoreNumber = 0;
  degree = 0;
  updateIncomeForm;
  updateIncomeErrorMsg = '';
  UpdateIncomeDisabled = true;

  personalLoanTick = false;
  homeLoanTick = false;
  carLoanTick = false
  creditCardTick = false;
  retailTick = false;

  personalLoanCross = false;
  homeLoanCross = false;
  carLoanCross = false
  creditCardCross = false;
  retailCross = false;

  personalLoanExclamation = false;
  homeLoanExclamation = false;
  carLoanExclamation = false
  creditCardExclamation = false;
  retailExcalamation = false;

  personalLoanDescription = '';
  homeLoanDescription = '';
  carLoanDescription = '';
  creditCardDescription = '';
  retailDescription = '';

  debtPercentageText = '';
  debtPercentageCross = false;
  debtPercentageTick = false;

  debtConsolidationText = '';

  errorMessage = '';
  campaignSourceType = 8;
  callBackErrorDebt = 'Please try after 15 minutes.';

  isWelcomeMsgActive = false;
  welcomeForm;
  welcomeMsg = '';
  welcomeTextId;

  dbc_Dcrs = 0; monthlyInstalment = 0; grossIncome = 0;
  debtConsolidationApplyButtonName = ''; debtConsolidationClosePopupButtonName = '';

  dcStatus; splStatus; vehicleFinanceStatus;
  vehicleFinanceBtnName = ''; vehicleFinanceCampaignSource = 0;
  monthlyExpensesForm; monthlyExpensesErrorMsg = '';
  categoryType = 0; vehiclePrice = 0; maxMonthlyInstallment = 0;
  closeCallMeBackPopupButtonName: string = "CallMeBackPopupButtonClosed"
  debtConsolidationCallMeButtonName: string;
  dcBannerCTAClicked = false;
  ifBannerDataExists = false; isProductQualifyCallComplete = false;
  appInsights: ApplicationInsights;
  heading = '';
  content = '';
  buttonText = '';
  routeToLink = '';
  linkCategory = '';
  rank = '';
  name = '';
  productTile = '';
  vehicleInterestRate: number = 0;
  isSplContractPending=false;
  constructor(
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private communicationService: CommunicationService,
    private location: PlatformLocation,
    private modalService: NgbModal,
    private oauthService: OAuthService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cookieService: CookieService,
    private welcomeService: WelcomeTextService,
    private productService: ProductService,
    private sapiService: SapiService,
    private registrationRouting: RegistrationRoutingService,
    private appInsightsService: AppInsightsService,
    private router: Router,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private eRef: ElementRef,
    private creditInsightService: CreditInsightService,
    private userOneSignalGetSubscriptionIdService: UserOneSignalGetSubscriptionIdService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };
    location.onPopState(() => this.modalService.dismissAll());
    this.appInsights = appInsightsService.appInsightsObj();
  }


  currDiv = 'A';
  ShowDiv(stepVal: string) {
    this.currDiv = stepVal;
  }

  toggleDisplay(event) {
    if (event.nextState === false) {
      this.isShowE = true;
    } else {
      this.isShowE = false;
    }

    this.isShowA = event.panelId === 'A-Panel' ? event.nextState : false;
    this.isShowB = event.panelId === 'B-Panel' ? event.nextState : false;
    this.isShowC = event.panelId === 'C-Panel' ? event.nextState : false;
    this.isShowD = event.panelId === 'D-Panel' ? event.nextState : false;
  }
  ngOnInit() {
    this.ChartId = ["areaChart", "areaChartDashboard", "areaChartMobile"];
    this.checkBannerData();
    localStorage.removeItem('tempToken');
    this.userData = this.oauthService.getIdentityClaims();
    if (!this.userData) {
      return;
    }

    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;

    this.welcomeForm = this.formBuilder.group({});

    // var loadData = sessionStorage.getItem('load');

    //this.getCreditHistory(() => {
    //  this.getCreditHistoryMonths(null);
    //  this.getCreditHealthInfo(() => {this.getProductsQualify(() => {
    //   
    //    this.getUserType(()=>{
    //      this.getLatestWelcomeMsg(null);               
    //    },null)},null);       
    //  },null);
    //  
    //  
    // 
    //},null);
    //

    this.load();

    this.updateIncomeForm = this.formBuilder.group({
      GrossIncome: ['', [Validators.required, Validators.min(0)]],
    });
    this.captureIncomeForm = this.formBuilder.group({
      GrossIncome: ['', [Validators.required, Validators.min(0)]],
    });

    this.monthlyExpensesForm = this.formBuilder.group({
      MonthlyExpenses: ['', [Validators.required, Validators.min(0)]],
    });

    this.accorToogle();
    this.InsertUtmSource();

    window.onscroll = function () { BannerStickySwitch() };
    var banner = document.getElementById("banner");
    var sticky = banner.offsetTop;
    var bannerHeight = $('.banr').height();

    function BannerStickySwitch() {
      sticky = banner.offsetTop;
      if (window.scrollY > sticky) {
        banner.classList.add("sticky");
        $('.stickybottomconainer').css('padding-top', bannerHeight + 'px');
      } else {
        banner.classList.remove("sticky");
        $('.stickybottomconainer').css('padding-top', '0' + 'px');
      }
    }

    this.carouselNormalization();
  }
  ngAfterViewInit() {
   
    this.route.queryParams.subscribe(params => {
      let scrollTo = params['dcid'];
      if (scrollTo) {
        const targetElement = this.eRef.nativeElement.querySelector('#' + scrollTo);
        console.log('aaaa', targetElement);
        if (targetElement) {
          this.scroll(targetElement);
        } else {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
      } else {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
    });
  }
  private scroll(targetElement: HTMLElement): void {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
  carouselNormalization() {
    $('.carousel-item').css('min-height', $('.carousel-item').height());
    $('.vdo-outer').css('min-height', $('.carousel-item').height());
  }
  //#region Load
  load() {
    var loadData = sessionStorage.getItem('load');
    if (loadData != null) {
      this.BindLoad(JSON.parse(loadData));
      return;
    }
    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    this.dashboardService.load(param).subscribe((data: any) => {
      this.IDMLeadautoInsert();
      this.BindLoad(data);
      this.getVehicleInterestRate();
      sessionStorage.setItem('load', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { });
  }

  private BindLoad(data: any) {

    var loadCreditHistory = sessionStorage.getItem('loadCreditHistory');
    var creditHistoryData = loadCreditHistory != null ? JSON.parse(loadCreditHistory) : data.creditHistory;

    sessionStorage.setItem('isCreditHistoryFetched', "true");

    this.getCreditHistory(null, creditHistoryData);

    var loadCreditHistoryMonths = sessionStorage.getItem('loadCreditHistoryMonths');
    var creditHistoryMonthsData = loadCreditHistoryMonths != null ? JSON.parse(loadCreditHistoryMonths) : data.creditHistoryMonths;
    this.getCreditHistoryMonths(creditHistoryMonthsData);




    var loadCreditHealthInfo = sessionStorage.getItem('loadCreditHealthInfo');
    var loadCreditHealthInfoData = loadCreditHealthInfo != null ? JSON.parse(loadCreditHealthInfo) : data.creditHealthInfo;
    this.getCreditHealthInfo(null, loadCreditHealthInfoData);

    var loadUserType = sessionStorage.getItem('loadUserType');
    var loadUserTypeData = loadUserType != null ? JSON.parse(loadUserType) : data.debtConsolidationSolution;
    this.getUserType(null, loadUserTypeData);
    this.getDebtConsolidationForDCproduct(loadUserTypeData);
    if (loadCreditHealthInfo != null) {
      this.checkGrossIncomeIsEmpty();
      this.getLatestWelcomeMsg(data.welcomeText);
    }

    var loadProductsQualifyData = sessionStorage.getItem('loadProductsQualify');
    this.getProductsQualify(JSON.parse(loadProductsQualifyData));
    this.GetCreditScoreGroupDetails();
  }

  GetCreditScoreGroupDetails() {
   
    var data = sessionStorage.getItem('creditInsightModel');
    if (data != null) {
      this.creditInsightData = JSON.parse(data);
     this.IsCreditInsightFetched=true;
    }
    else {     
      this.creditInsightService.GetCreditInsightDetails().subscribe((data: any) => {
        this.IsCreditInsightFetched=true;
        if (data != null) {
        this.creditInsightData = data?.creditInsightModel;
        // Store the creditInsightModel in session storage
        sessionStorage.setItem('creditInsightModel', JSON.stringify(data?.creditInsightModel));
        }
      },
        (err: HttpErrorResponse) => {
         
        }
      );
    }
    
   
  }

  //#endregion Load

  accorToogle() {

  }

  get welcomeFormControls() { return this.welcomeForm.controls; }
  get captureIncomeFormControls() { return this.captureIncomeForm.controls; }
  get monthlyExpensesFormControls() { return this.monthlyExpensesForm.controls; }
  get updateIncomeFormControls() { return this.updateIncomeForm.controls; }

  //#region CreditHistory

  getCreditHistory(callback, data) {
    if (data != null) {
      this.BindCreditHistory(callback, data);
      return;
    }
    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    if (sessionStorage.getItem('isCreditHistoryFetched') != "true") {
      this.dashboardService.creditHistory(param).subscribe((data: any) => {
        this.BindCreditHistory(callback, data);
      },
        (err: HttpErrorResponse) => {
        });
    }

  }

  private BindCreditHistory(callback, data) {
    sessionStorage.removeItem('loadCreditHistory')
    sessionStorage.setItem('loadCreditHistory', JSON.stringify(data));

    this.isDataAvailable = data.isDataAvailable;
    this.creditHistory = data;
    this.scoreProgress = data?.scoreInformation?.scorePercent;
    this.scoreNumber = data?.scoreInformation?.score;
    this.degree = (254 / 1000) * this.scoreNumber - 126;

    this.scoreAgeProgress = data?.scoreInformation?.scoreAge;
    this.co();
    //this.scoreStatus = data.uiTextElement.scoreType.replace(/([A-Z])/g, ' $1').trim();
    if (data.uiTextElement != null) {
      this.scoreColor = data.uiTextElement.colorCode;
      $('.bashbord-invision .donut-segment-avg').css('stroke', data.uiTextElement.colorCode);
    }
    if (this.cookieService.get('userId') === '') {
      const isSecure = window.location.origin.startsWith('https');
      this.cookieService.set('userId', data.userId, null, '/', undefined, isSecure, 'Strict');
    }

    if (callback) {
      callback();
    }
  }

  //#endregion CreditHistory

  //#region UserType
  getUserType(callback, data) {
    if (data != null) {
      this.BindUserType(callback, data);
      return;
    }
    this.userService.getDebtConsolidation().subscribe((data: any) => {
      this.BindUserType(callback, data);
    }, (err: HttpErrorResponse) => {
    });

  }

  private BindUserType(callback, data) {
    sessionStorage.removeItem('loadUserType')
    sessionStorage.setItem('loadUserType', JSON.stringify(data));
    this.userType = data.dbcUserType;

    if (callback) {
      callback();
    }
  }

  //#endregion UserType

  co() {
    if (this.scoreNumber >= 0 && this.scoreNumber <= 2) {
      this.scoreStatus = 'No Info';
    }
    if (this.scoreNumber >= 3 && this.scoreNumber <= 600) {
      this.scoreStatus = 'Not good';
    }
    if (this.scoreNumber >= 601 && this.scoreNumber <= 800) {
      this.scoreStatus = 'Needs work';
    }
    if (this.scoreNumber >= 801 && this.scoreNumber <= 850) {
      this.scoreStatus = 'Okay';
    }
    if (this.scoreNumber >= 851 && this.scoreNumber <= 900) {
      this.scoreStatus = 'Good';
    }
    if (this.scoreNumber >= 901 && this.scoreNumber <= 1000) {
      this.scoreStatus = 'Excellent';
    }
  }

  //#region CreditHealthInfo
  getCreditHealthInfo(callback, data) {
    if (data != null) {
      this.BindCreditHealthInfo(callback, data);
      return;
    }
    if (sessionStorage.getItem('isCreditHistoryFetched') != "true") {
      const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
      this.dashboardService.creditHealthInfo(param).subscribe((data: any) => {
        this.BindCreditHealthInfo(callback, data);

        //  sessionStorage.removeItem('load');//remove load so that next time fresh data can load.
        //    this.load();
      },
        (err: HttpErrorResponse) => {
        });
    }

  }
  private BindCreditHealthInfo(callback, data) {
    sessionStorage.removeItem('loadCreditHealthInfo')
    sessionStorage.setItem('loadCreditHealthInfo', JSON.stringify(data));

    this.creditHealthInfo = data.creditHealthInfo;
    this.creditHealthInfoUiTextElement = data.creditHealthInfoUiTextResponse;
    this.personalLoanDescription = data.creditHealthInfoUiTextResponse.personalLoan.description;
    this.homeLoanDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreHomeLoan.description;
    this.carLoanDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreVehicleFinance.description;
    this.creditCardDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreCreditCard.description;
    this.retailDescription = data.creditHealthInfoUiTextResponse.retail != null ? data.creditHealthInfoUiTextResponse.retail.description : '';
    this.getQualify(data.creditHealthInfoUiTextResponse);
    this.getDebtPercentageText(data.creditHealthInfo);
    this.userType = data.dbcUserType;
    //const isSecure = window.location.origin.startsWith('https');
    // this.cookieService.set('uType', data.dbcUserType, null, '/', undefined, isSecure);

    var date = new Date();
    var hour = date.getUTCHours() + 2;
    var day = date.getDay();
    var minutes = date.getUTCMinutes();

    if (day == 1 || day == 2 || day == 3 || day == 4) {
      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 17) {
        this.createChat();
      }
    }
    if (day == 5) {
      if (hour == 8 && minutes >= 30) {
        this.createChat();
      }
      if (hour >= 8 && hour <= 16) {
        this.createChat();
      }
    }
    this.checkGrossIncomeIsEmpty();

    if (callback) {
      callback();
    }


  }

  //#endregion CreditHealthInfo

  getQualify(scoreElementInformation) {

    if (this.personalLoanDescription == 'Very Low Chance' || this.personalLoanDescription == 'Low Chance') {
      this.personalLoanCross = true
    }
    if (this.personalLoanDescription == 'Very High Chance' || this.personalLoanDescription == 'High Chance') {
      this.personalLoanTick = true
    }
    if (this.personalLoanDescription == 'Moderate Chance') {
      this.personalLoanExclamation = true
    }

    if (this.homeLoanDescription == 'Very Low Chance' || this.homeLoanDescription == 'Low Chance') {
      this.homeLoanCross = true
    }
    if (this.homeLoanDescription == 'Very High Chance' || this.homeLoanDescription == 'High Chance') {
      this.homeLoanTick = true
    }
    if (this.homeLoanDescription == 'Moderate Chance') {
      this.homeLoanExclamation = true
    }

    if (this.carLoanDescription == 'Very Low Chance' || this.carLoanDescription == 'Low Chance') {
      this.carLoanCross = true
    }
    if (this.carLoanDescription == 'Very High Chance' || this.carLoanDescription == 'High Chance') {
      this.carLoanTick = true
    }
    if (this.carLoanDescription == 'Moderate Chance') {
      this.carLoanExclamation = true
    }

    if (this.creditCardDescription == 'Very Low Chance' || this.creditCardDescription == 'Low Chance') {
      this.creditCardCross = true
    }
    if (this.creditCardDescription == 'Very High Chance' || this.creditCardDescription == 'High Chance') {
      this.creditCardTick = true
    }
    if (this.creditCardDescription == 'Moderate Chance') {
      this.creditCardExclamation = true
    }

    if (this.retailDescription == 'Very Low Chance' || this.retailDescription == 'Low Chance') {
      this.retailCross = true
    }
    if (this.retailDescription == 'Very High Chance' || this.retailDescription == 'High Chance') {
      this.retailTick = true
    }
    if (this.retailDescription == 'Moderate Chance') {
      this.retailExcalamation = true
    }
  }

  getDebtPercentageText(creditHealthInfo) {

    if (creditHealthInfo.salaryGoingTowardDebtPercent > 80) {
      this.debtPercentageText = 'Very Low Chance';
    }
    else if (creditHealthInfo.salaryGoingTowardDebtPercent >= 61 && creditHealthInfo.salaryGoingTowardDebtPercent <= 80) {
      this.debtPercentageText = 'Low Chance';
    }
    else if (creditHealthInfo.salaryGoingTowardDebtPercent >= 41 && creditHealthInfo.salaryGoingTowardDebtPercent <= 60) {
      this.debtPercentageText = 'Moderate Chance';
    }
    else if (creditHealthInfo.salaryGoingTowardDebtPercent >= 21 && creditHealthInfo.salaryGoingTowardDebtPercent <= 40) {
      this.debtPercentageText = 'High Chance';
    }
    else if (creditHealthInfo.salaryGoingTowardDebtPercent >= 0 && creditHealthInfo.salaryGoingTowardDebtPercent <= 20) {
      this.debtPercentageText = 'Very High Chance';
    }

    if (creditHealthInfo.salaryGoingTowardDebtPercent > 35 && creditHealthInfo.totalCurrentBalance > 35000) {
      this.debtConsolidationText = 'High Chance';
      this.debtPercentageCross = false;
      this.debtPercentageTick = true;
    }
    else {
      this.debtConsolidationText = 'Low Chance';
      this.debtPercentageCross = true;
      this.debtPercentageTick = false;
    }
  }

  getCreditHistoryMonths(data) {
    if (data != null) {
      this.BindCreditHistoryMonths(data);
      return;
    }
    if (sessionStorage.getItem('isCreditHistoryFetched') != "true") {
      this.dashboardService.creditHistoryMonths().subscribe((data: any) => {
        this.BindCreditHistoryMonths(data);
      },
        (err: HttpErrorResponse) => {
        });
    }

  }

  private BindCreditHistoryMonths(data) {
    sessionStorage.removeItem('loadCreditHistoryMonths')
    sessionStorage.setItem('loadCreditHistoryMonths', JSON.stringify(data));

    this.creditHistoryMonths = data;
    if (data.length > 0) {
      this.creditHistoryMonth = new Date(`${data[0].reportDate}`).toLocaleDateString(undefined, { month: 'long' }) + ', ' + data[0].year;
    }
  }


  // creditReportByMonth(event) {
  //   this.creditReportYear = event.target.value.split('-')[0];
  //   this.creditReportMonth = event.target.value.split('-')[1];
  //   this.getCreditHistory(null, null);
  //   this.getCreditHealthInfo(null, null);
  // }

  callbackPopup() {
    this.campaignSourceType = 7;
    this.callbackSuccessMsg = '';
    this.callbackErrorMsg = '';
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }

  leadCallback(RequestFromBanner?: boolean) {
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.closeCallMeBackPopupButtonName = "btn_DebtConsolidationCallMe_High_Dashboard_Closed"
    const param = { CampaignSource: 'CreditSav_Solution', Referrer: 'Justmoney', WebSource: 'CreditSav_Solution' };
    this.communicationService.leadCallbackSource(param).subscribe((data: any) => {
      this.callbackSuccessMsg == '';
      this.callbackSuccessMsg = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      if (!RequestFromBanner) {
        this.modalService.open(this.callbackSuccessModel, this.modalOptions);
      }
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Something went wrong. Please try again later';
    });
  }

  checkGrossIncomeIsEmpty() {
    if (this.creditHealthInfo.grossIncome == null) {
      setTimeout(() => {
        this.modalService.open(this.captureIncomeModel, this.modalOptions);
      }, 1000);
    }
  }

  showUpdateIncomeModel() {
    if (this.isDataAvailable) {
      this.UpdateIncomeDisabled = true;
      this.updateIncomeForm.patchValue({
        GrossIncome: "R " + this.creditHealthInfo.grossIncome
      });
      this.modalService.open(this.updateIncomeModel, this.modalOptions);
    }
  }

  updateIncome() {
    this.captureIncomeErrorMsg = '';
    if (this.captureIncomeForm.invalid) {
      this.captureIncomeForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.captureIncomeForm.value.GrossIncome) >= 0) {
      this.captureIncomeForm.value.GrossIncome = parseFloat(this.toNumber(this.captureIncomeForm.value.GrossIncome));
    }

    this.userService.updateGrossIncome(this.captureIncomeForm.value).subscribe((data: any) => {
      this.modalService.dismissAll();

      sessionStorage.removeItem('load');
      sessionStorage.removeItem('loadCreditHistory');
      sessionStorage.removeItem('isCreditHistoryFetched');
      sessionStorage.removeItem('loadCreditHistoryMonths');
      sessionStorage.removeItem('loadCreditHealthInfo');
      sessionStorage.removeItem('loadAccountCreditHealthInfo')
      sessionStorage.removeItem('loadUserType');
      this.load();
      this.checkWingfieldUser();

      //   this.getCreditHealthInfo(() => {
      //     this.getLatestWelcomeMsg(null);
      //     this.checkWingfieldUser();
      //     
      //   }, null);

    },
      (err: HttpErrorResponse) => {
        this.captureIncomeErrorMsg = err.error;
      });
  }

  enableUpdateIncome() {
    var regexPatternIncome = /^R\d+/;
    var income = this.updateIncomeForm.value.GrossIncome.toString().replace(/\s/g, "");
    this.UpdateIncomeDisabled = false;

    if (this.updateIncomeForm.value.GrossIncome.toString().trim() === 'R' || !(regexPatternIncome.test(income))) {
      this.updateIncomeForm.patchValue({
        GrossIncome: ''
      });
      this.UpdateIncomeDisabled = true;
    }
  }

  updateGrossIncome() {
    this.updateIncomeErrorMsg = '';
    if (this.updateIncomeForm.invalid) {
      this.updateIncomeForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.updateIncomeForm.value.GrossIncome) >= 0) {
      this.updateIncomeForm.value.GrossIncome = parseFloat(this.toNumber(this.updateIncomeForm.value.GrossIncome));
    }
    this.userService.updateGrossIncome(this.updateIncomeForm.value).subscribe((data: any) => {
      this.modalService.dismissAll();
      this.updateLoad();
      this.getDebtConsolidationOnIncomeUpdate();
    },
      (err: HttpErrorResponse) => {
        this.updateIncomeErrorMsg = err.error;
      });
  }
  getDebtConsolidationOnIncomeUpdate() {
    this.userService.getDebtConsolidation().subscribe((data: any) => {
    }, (err: HttpErrorResponse) => {
    });
  }

  updateLoad() {
    var loadData = sessionStorage.getItem('load');
    var data = JSON.parse(loadData);
    const param = { Year: this.creditReportYear, Month: this.creditReportMonth };
    this.dashboardService.creditHealthInfo(param).subscribe((creditHealthinfo: any) => {
      this.BindCreditHealthInfo(null, creditHealthinfo);
      this.getProductsQualify(null);
      data.creditHealthInfo = creditHealthinfo;
      sessionStorage.setItem('load', JSON.stringify(data));
      sessionStorage.setItem('loadAccountCreditHealthInfo', JSON.stringify(creditHealthinfo));
    },
      (err: HttpErrorResponse) => {
      });
  }

  getDebtConsolidation() {
    this.userService.getDebtConsolidation().subscribe((data: any) => {
      this.dbc_Dcrs = data.dbC_DCRS;
      this.grossIncome = data.grossIncome;
      this.monthlyInstalment = data.monthlyInstallment;

      this.debtConsolidationApplyButtonName = 'btn_DebtConsolidationCallMe_Dashboard_APPLYNOW';
      this.debtConsolidationClosePopupButtonName = 'btn_DebtConsolidationCallMe_Dashboard_closed';
      this.debtConsolidationCallMeButtonName = 'btn_DebtConsolidationCallMe_Dashboard_CallMe';
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
    });

    this.modalService.open(this.debtConsolidationModel, this.modalOptions);
  }

  getDebtConsolidationForDCproduct(data) {

    if (this.creditHealthInfo.grossIncome != null) {
      this.dbc_Dcrs = data.dbC_DCRS;
      this.grossIncome = data.grossIncome;
      this.monthlyInstalment = data.monthlyInstallment;

      this.debtConsolidationApplyButtonName = 'btn_DebtConsolidationCallMe_Dashboard_APPLYNOW';
      this.debtConsolidationClosePopupButtonName = 'btn_DebtConsolidationCallMe_Dashboard_closed';
      this.debtConsolidationCallMeButtonName = 'btn_DebtConsolidationCallMe_Dashboard_CallMe';
    }

  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  changeGrossIncome() {
    if (this.captureIncomeForm.value.GrossIncome.toString().trim() === 'R') {
      this.captureIncomeForm.patchValue({
        GrossIncome: ''
      });
    }
  }

  createChat() {
    if (!environment.hideChat) {
      if ((window as any).fcWidget) {

        (window as any).fcWidget.init({
          token: this.env.freshChatToken,
          host: this.env.freshChatHost,
          tags: ["JustMoney"],
          externalId: this.userData.name,     // user’s id unique to your system
          firstName: this.userData.given_name,              // user’s first name
          lastName: this.userData.family_name
        });
      }
    }

  }

  userCallback() {
    var payload = {
      CampaignSourceType: this.campaignSourceType
    }
    this.communicationService.userCallbackSource(payload).subscribe((data: any) => {
      $(".txthd").hide();
      $(".modal-footer").hide();
      this.callbackSuccessMsg == '';
      this.callbackSuccessMsg = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackSuccessModel, this.modalOptions)
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Our coach will contact you shortly.';
      this.callBackErrorDebt = 'Our coach will contact you shortly.';
      this.modalService.dismissAll();
      this.modalService.open(this.callbackErrorModel, this.modalOptions);
    });
  }

  // product page impleamentation

  personalLoanOffer() {

    this.closeCallMeBackPopupButtonName = "btn_SPLViewOffer_Dashboard_Closed"
    this.modalService.open(this.lesApprovedModal, this.modalOptions);
    //     this.communicationService.lesQualificationDecision().subscribe((data: any) => {

    //   if (data.decision === 'Approve') {
    //     //Fincheck flow
    //   }
    //   // else if(data.decision === 'Decline' && data.decisionReasons[0] === 'LES: Active Application Status Decline Result'){
    //   //   this.modalService.open(this.lesDeclineModal, this.modalOptions);
    //   // }
    //   else {
    //     this.personalLoansCallback();
    //   }
    // }, (err: HttpErrorResponse) => {
    //   this.errorMessage = 'Something went wrong. Please try again later'
    // });
  }

  transferLead() {

    this.communicationService.transferLead().subscribe((data: any) => {
      if (data.responseCode === '200') {
        window.open(data.redirectUrl, '_blank');
      }
      else {
        this.modalService.dismissAll();
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.finCheckErrorModal, this.modalOptions);
    });
  }

  fincheckLead() {

    this.communicationService.transferLeadFincheck().subscribe((data: any) => {

      if (data.code == "200") {
        this.modalService.dismissAll();
        this.modalService.open(this.finCheckSubmitModal, this.modalOptions);
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.finCheckErrorModal, this.modalOptions);
    }
    );
  }

  debtConsolidationCallback() {
    if (this.dcStatus != null) {
      if (this.dcStatus.criteria == 'no-no') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationLow;
      } else if (this.dcStatus.criteria == 'no-yes') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationHigh2;
      } else if (this.dcStatus.criteria.substring(0, 3) == 'yes') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationUnderDC;
      } else {
        this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidation;
      }
    }
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback();
    //window.location.href = "https://www.justmoney.co.za/debt-consolidation/debt-consolidation";
  }

  debtConsolidationCallbackApplyNow() {
    this.closeCallMeBackPopupButtonName = "btn_DebtConsolidationCallMe_DashboardPage_Closed"
    this.campaignSourceType = Enum.LmsCampaignSourceType.DebtConsolidationHigh;
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback();
  }

  personalLoansCallback() {
    this.campaignSourceType = 9;
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }

  openCarInsurance() {
    this.modalService.open(this.carInsuranceConfirmationModel, this.modalOptions);
  }

  carInsuranceCallback() {
    this.communicationService.transferUserToTCImporterLead().subscribe((data: any) => {

      if (data.errorCode === '0') {
        this.modalService.dismissAll();
        this.modalService.open(this.carInsuranceSuccessModel, this.modalOptions);
      }
      else {
        this.modalService.dismissAll();
        this.modalService.open(this.callbackErrorModel, this.modalOptions);
      }
    }, (err: HttpErrorResponse) => {
      this.modalService.dismissAll();
      this.modalService.open(this.callbackErrorModel, this.modalOptions);
    })
  }

  //#region LatestWelcomeMsg

  getLatestWelcomeMsg(data) {
    if (this.creditHealthInfo.grossIncome == null || this.creditHealthInfo.grossIncome == '') {

      return;
    }

    if (data != null) {
      this.BindLatestWelcomeMsg(data);
      return;
    }

    this.welcomeService.getLatestWelcomeMessage().subscribe((data: any) => {
      this.BindLatestWelcomeMsg(data);
    },
      (err: HttpErrorResponse) => {
      });
  }

  private BindLatestWelcomeMsg(data: any) {

    this.isWelcomeMsgActive = data.showPopUp;
    if (this.isWelcomeMsgActive === true) {
      this.welcomeMsg = data.welcomeText.description;
      this.welcomeTextId = data.welcomeText.id;
      this.checkWelcomeIsEmpty();
    }
    else {
      //this.getCreditHealthInfo(() => {  //once check is this call extra, if extra then remove
      this.checkGrossIncomeIsEmpty();
      //}, null);
    }
  }
  //#endregion LatestWelcomeMsg

  checkWelcomeIsEmpty() {
    setTimeout(() => {
      this.modalService.open(this.welcomeModel, this.modalOptions);
    }, 1000);
    //this.modalService.open(this.welcomeModel);
  }

  updateWelcome() {
    this.modalService.dismissAll();

    const param = { WelcomeTextId: this.welcomeTextId, IdNumber: this.userData.name };

    this.welcomeService.updatedWelcomeId(param).subscribe((data: any) => {
      //this.getCreditHealthInfo(() => {
      this.checkGrossIncomeIsEmpty();
      //  sessionStorage.removeItem('load');//remove load so that next time fresh data can load.
      //  this.load();
      //}, null);
    });
  }

  //#region ProductsQualify

  getProductsQualify(data) {
    if (data != null) {
      this.BindProductQualify(data);
      return;
    }


    this.productService.getProductsQualify().subscribe((data: any) => {
      if (data != null) {
        this.BindProductQualify(data);
      }
      this.userOneSignalGetSubscriptionIdService.getAndSendSubscriptionId();
    });
  }

  private BindProductQualify(data) {
    sessionStorage.removeItem('loadProductsQualify')
    sessionStorage.setItem('loadProductsQualify', JSON.stringify(data));
    this.dcStatus = data.dcResponse;
    this.splStatus = data.splResponse;
    this.isSplContractPending=this.splStatus?.isInPendindingState;
    this.vehicleFinanceStatus = data.vehicleResponse;

    if (this.vehicleFinanceStatus != null) {
      switch (this.vehicleFinanceStatus.criteria) {
        case "0-19": {
          this.vehicleFinanceBtnName = 'btn_VehichleFinanceLow_Dashboard';
          this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceLow;
          break;
        }
        case "20-64": {
          this.vehicleFinanceBtnName = 'btn_VehichleFinanceMed_Dashboard';
          this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceMedium;
          break;
        }
        case "65-89": {
          this.vehicleFinanceBtnName = 'btn_VehichleFinanceHigh_Dashboard';
          this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceHigh;
          break;
        }
        case "90-10000": {
          this.vehicleFinanceBtnName = 'btn_VehichleFinanceVeryHigh_Dashboard';
          this.vehicleFinanceCampaignSource = Enum.LmsCampaignSourceType.WingfieldFinanceVeryHigh;
          break;
        }
      }
    }
    this.isProductQualifyCallComplete = true;
    if (this.dcBannerCTAClicked && this.dcStatus != null) {
      //when banner CTA clicked for call me and product qualify returns
      if (this.dcStatus.criteria == 'no-yes') {
        this.leadCallback();
      }
      else {
        this.debtConsolidationCallback();
      }

    }
  }

  //#endregion ProductsQualify

  splCallbackApplyNow() {
    if (this.splStatus != null) {
      if (this.splStatus.criteria == 'Maybe-Low') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPLDeclineMedium;
      } else if (this.splStatus.criteria == 'Decline') {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPLDeclineLow;
      } else {
        this.campaignSourceType = Enum.LmsCampaignSourceType.SPL;
      }
    }
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.closeCallMeBackPopupButtonName = "btn_SPLCallMe_Dashboard_Closed"
    this.userCallback();
  }

  vehicleFinanceCallback() {
    this.closeCallMeBackPopupButtonName = "btn_VehichleFinance_Closed"
    this.campaignSourceType = this.vehicleFinanceCampaignSource;
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.userCallback();
  }

  checkWingfieldUser() {
    this.userService.IsWingfieldUser().subscribe((data: any) => {
      if (data == true) {
        // this.modalService.open(this.monthlyExpenses, this.modalOptions);
      } else {
        if (this.userType == 3) {
          this.getDebtConsolidation();
        }
      }

    }, (err: HttpErrorResponse) => {
    });
  }

  saveMonthlyExpenses() {
    this.monthlyExpensesErrorMsg = '';
    if (this.monthlyExpensesForm.invalid) {
      this.monthlyExpensesForm.markAllAsTouched();
      return;
    }

    if (this.toNumber(this.monthlyExpensesForm.value.MonthlyExpenses) >= 0) {
      this.monthlyExpensesForm.value.MonthlyExpenses = parseFloat(this.toNumber(this.monthlyExpensesForm.value.MonthlyExpenses));
    }

    this.userService.saveMonthlyExpenses(this.monthlyExpensesForm.value).subscribe((data: any) => {
      this.modalService.dismissAll();
      if (data != null) {
        this.categoryType = data.categoryType;
        this.vehiclePrice = Math.round(data.vehiclePrice / 10000) * 10000;
        this.maxMonthlyInstallment = Math.round(data.maxMonthlyInstallment / 500) * 500;
        this.modalService.open(this.financeIndicator, this.modalOptions);
      }

    },
      (err: HttpErrorResponse) => {
        this.monthlyExpensesErrorMsg = err.error;
      });
  }

  changeMonthlyExpenses() {
    if (this.monthlyExpensesForm.value.MonthlyExpenses.toString().trim() === 'R') {
      this.monthlyExpensesForm.patchValue({
        MonthlyExpenses: ''
      });
    }
  }
  setClassesScoreAgeProgress() {
    return {
      'color1': this.scoreAgeProgress < 10,
      'color2': this.scoreAgeProgress > 10 && this.scoreAgeProgress < 20,
      'color3': this.scoreAgeProgress > 20 && this.scoreAgeProgress < 30,
      'color4': this.scoreAgeProgress > 30 && this.scoreAgeProgress < 40,
      'color5': this.scoreAgeProgress > 40 && this.scoreAgeProgress < 50,
      'color6': this.scoreAgeProgress > 50 && this.scoreAgeProgress < 60,
      'color7': this.scoreAgeProgress > 60 && this.scoreAgeProgress < 70,
      'color8': this.scoreAgeProgress > 70 && this.scoreAgeProgress < 80,
      'color9': this.scoreAgeProgress > 80 && this.scoreAgeProgress < 90,
      'color10': this.scoreAgeProgress > 90 && this.scoreAgeProgress < 100
    }
  }
  setClassesScoreProgress() {
    return {
      'color1': this.scoreProgress < 10,
      'color2': this.scoreProgress > 10 && this.scoreProgress < 20,
      'color3': this.scoreProgress > 20 && this.scoreProgress < 30,
      'color4': this.scoreProgress > 30 && this.scoreProgress < 40,
      'color5': this.scoreProgress > 40 && this.scoreProgress < 50,
      'color6': this.scoreProgress > 50 && this.scoreProgress < 60,
      'color7': this.scoreProgress > 60 && this.scoreProgress < 70,
      'color8': this.scoreProgress > 70 && this.scoreProgress < 80,
      'color9': this.scoreProgress > 80 && this.scoreProgress < 90,
      'color10': this.scoreProgress > 90 && this.scoreProgress < 100
    }
  }

  //----------------------------


  sapiSignUp() {
    const param = { pageName: 'dashboard' };
    this.sapiService.sapiSignUp(param).subscribe((data: any) => {
      if (data != null) {
        if (data.statusCode == 302 && data.returnUrl != '') {
          window.location.href = data.returnUrl;
        }
      }
    },
      (err: HttpErrorResponse) => {
        this.monthlyExpensesErrorMsg = err.error;
      });
  }

  InsertUtmSource() {
    var isUtmAvailable = false;

    if (localStorage.getItem('UtmParams') !== null) {
      isUtmAvailable = true;
      var payload = {
        WebSource: '',
        UtmParams: localStorage.getItem('UtmParams'),

      }
    }
    else if (localStorage.getItem('query_params') !== null) {
      isUtmAvailable = true;
      var payload = {
        WebSource: '',
        UtmParams: localStorage.getItem('query_params'),

      }
    }

    if (isUtmAvailable) {
      this.dashboardService.InsertUtmTracking(payload).subscribe(() => {
        setTimeout(() => localStorage.removeItem('UtmParams'), 300);
        setTimeout(() => localStorage.removeItem('query_params'), 300);
      });
    }
  }

  checkBannerData() {
    var utmParams = localStorage.getItem('UtmParams');


    if (utmParams != null) {
      if (utmParams.toLowerCase().includes("utm_id")) {
        if (utmParams.charAt(0) == '?' || utmParams.charAt(0) == '&') {
          utmParams = utmParams.slice(1);
          var utmParamsList = utmParams.split('&');
          var campaignUtmId = utmParamsList.find(item => item.toLowerCase().startsWith('utm_id'));
          this.IsCampaignAndLinkedBannerActive(campaignUtmId)
          sessionStorage.setItem('CampaignJourney', campaignUtmId);
        }
      }
    }
    else if (sessionStorage.getItem('CampaignJourney') != null) {
      this.IsCampaignAndLinkedBannerActive(sessionStorage.getItem('CampaignJourney'));
    }
  }


  clearBannerData() {
    sessionStorage.removeItem('CampaignJourney');
    sessionStorage.removeItem('BannerData');
    sessionStorage.removeItem('BannerShowTime');
  }

  HideBanner() {
    var campaignUtmId = sessionStorage.getItem('CampaignJourney');
    var cuid = campaignUtmId.split('=')[1];
    var bannerName = JSON.parse(sessionStorage.getItem('BannerData')).name;
    this.trackBanner(false, true);
    this.clearBannerData();
  }

  plBannerCTA() {
    this.trackBanner(true, false);
    this.clearBannerData();

  }
  redirectToProductPage() {
    const navigationExtras: NavigationExtras = {
      queryParams: { pid: this.productTile }
    };
    this.router.navigate([this.routeToLink], navigationExtras);
  }
  competitionCTA() {

    this.trackBanner(true, false);
    this.redirectToProductPage();
    this.clearBannerData();

  }

  IsCampaignAndLinkedBannerActive(campaignUtmId: string) {
    var cuId = campaignUtmId.slice(6).split('=')[1];


    if (sessionStorage.getItem('BannerData') != null) {
      this.getBannerDetails(campaignUtmId);
    } else {
      const param = { campaignUtmId: cuId };

      this.dashboardService.getCampaignAndLinkedBannerData(param).subscribe((data: any) => {
        if (data) {

          sessionStorage.setItem('BannerData', JSON.stringify(data));
          sessionStorage.setItem('BannerShowTime', JSON.stringify(new Date()));
          this.getBannerDetails(campaignUtmId);

        }

      }, (err: HttpErrorResponse) => {

      });
    }

  }
  IDMLeadautoInsert() {
    this.communicationService.IDMLeadautoInsert().subscribe((data: any) => { });
  }

  getBannerDetails(campaignUtmId: string) {
    var cuId = campaignUtmId.slice(6).split('=')[1];
    const param = { campaignUtmId: cuId };
    var bannerSession = sessionStorage.getItem('BannerData');
    var bannerJson = JSON.parse(bannerSession);
    this.heading = bannerJson.heading;
    this.rank = bannerJson.rank;
    this.name = bannerJson.name;
    this.content = bannerJson.content;
    this.buttonText = bannerJson.buttonText;
    this.routeToLink = bannerJson.routeToLink;
    this.linkCategory = bannerJson.linkCategory;
    this.productTile = bannerJson.productTile;
    if (this.heading && this.name && this.buttonText && this.routeToLink && this.linkCategory) {
      this.ifBannerDataExists = true;
    }

    // this.trackBanner(false,false);

  }

  openExternalUrl() {
    window.open(this.routeToLink, '_blank');
  }

  callOffPlatformNavigate() {
    this.trackBanner(true, false);
    this.openExternalUrl();
    this.clearBannerData();
    this.hideBanner();

  }

  hideBanner() {
    document.getElementById('banner').style.display = 'none';
  }

  trackBanner(Click_CTA, Click_Hide) {
    var campaignUtmId = sessionStorage.getItem('CampaignJourney');
    var cuId = campaignUtmId.split('=')[1];
    var bannerName = JSON.parse(sessionStorage.getItem('BannerData')).name;
    var interactionTime = null;
    if (Click_CTA || Click_Hide) {
      if (sessionStorage.getItem('BannerShowTime') != null) {
        var startTime = new Date(JSON.parse(sessionStorage.getItem('BannerShowTime'))).getTime();
        var endTime = (new Date()).getTime();
        var timeDifference = endTime - startTime;

        var interactionTimeInHours = this.convertToDoubleDigit(Math.floor(timeDifference / (1000 * 60 * 60)));
        var interactionTimeInMinutes = this.convertToDoubleDigit(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)));
        var interactionTimeInSeconds = this.convertToDoubleDigit(Math.floor((timeDifference % (1000 * 60)) / 1000));
        interactionTime = `${interactionTimeInHours} : ${interactionTimeInMinutes} : ${interactionTimeInSeconds} `;
      }
    }
    this.trackBannerEventAppInsights(cuId, bannerName, Click_CTA, Click_Hide, interactionTime);
  }

  trackBannerEventAppInsights(campaignUtmId, bannerName, click_CTA, clickHide, interactionTime) {

    if (this.env.appInsights.isEnable) {
      this.appInsights.trackEvent({
        name: campaignUtmId,
        properties: {
          SubmissionId: sessionStorage.getItem('submissionId'),
          SessionId: sessionStorage.getItem('sessionId'),
          UserId: this.cookieService.get('userId'),
          Utm_ID: campaignUtmId,
          BannerName: bannerName,
          Click_CTA: click_CTA,
          Click_Hide: clickHide,
          InteractionTime: interactionTime
        }
      });
    }
  }

  convertToDoubleDigit(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }
  onResize(_evt: any) {
    this.carouselNormalization();
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    //navSpeed: 700,
    autoplay: true,
    autoplayTimeout:1500,
    autoplayHoverPause:true,
    //navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }
  getVehicleInterestRate(): void {
    this.productService.GetVehicleFinanceInterestRate().subscribe(
      (data: any) => {
        this.vehicleInterestRate = data?.interestRate ?? 11.75;
        sessionStorage.setItem('vehicleInterestRate', this.vehicleInterestRate.toString());
      },
      (error: any) => {
        console.error('Error fetching vehicle finance interest rate:', error);
        this.vehicleInterestRate = 11.75; // Use default value in case of error
      }
    );
  }


}

