import { Component, HostBinding, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from '../services/communication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  env = environment;
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  @ViewChild('LoginBackError', { static: false }) LoginBackError: ElementRef;
  @HostBinding('style.width') public readonly WIDTH = '100%';
  userData = null;
  title = 'webapp';
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  modalOptions: NgbModalOptions;
  constructor(
    private userService: UserService,
    public router: Router,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private modalService: NgbModal,
    private communicationService: CommunicationService,
    private spinner:NgxSpinnerService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
  }

  ngOnInit(): void {
    this.userData = this.oauthService.getIdentityClaims();
    this.bindNavBar();

    let type = this.cookieService.get('uType');
    if (type === '3') {
      $('#liFinancialWellness').show();
      
      var date = new Date();
      var hour = date.getUTCHours() + 2;
      // if(hour >= 7 && hour <= 18){
      //     if ((window as any).fcWidget){
      //       console.log("window service__2", document.referrer)
      //       if(this.cookieService.get('chatEnabled') == "true"){
      //         (window as any).fcWidget.init({
      //             token: this.env.freshChatToken,
      //             host: this.env.freshChatHost,
      //             tags: ["DBclient"],
      //             externalId: this.userData.name,     // user’s id unique to your system
      //             firstName: this.userData.given_name,              // user’s first name
      //             lastName: this.userData.family_name
      //         });
      //       }
      //       else{
      //         setTimeout(() => {
      //         console.log("calling set timeout");
      //         const isSecure = window.location.origin.startsWith('https');
      //         this.cookieService.set('chatEnabled', "true", null, '/', undefined, isSecure);
      //         (window as any).fcWidget.init({
      //             token: this.env.freshChatToken,
      //             host: this.env.freshChatHost,
      //             tags: ["DBclient"],
      //             externalId: this.userData.name,     // user’s id unique to your system
      //             firstName: this.userData.given_name,              // user’s first name
      //             lastName: this.userData.family_name
      //         });     
      //         }, 60 * 1000);
      //       }
      //     }
      //   }
    } else {
      $('#liFinancialWellness').hide();
    }
  }

  bindNavBar() {
    const backDrop = '<div class="backdrop"></div>';
    $('.navbar-toggler').click(function() {
      $('body').toggleClass('push-menu-body');
      $('.navbar').toggleClass('push-menu-visible');
      //$(backDrop).appendTo('body').on('click', function() {
        //$('.navbar').removeClass('push-menu-visible');
        //$(this).remove();
      //});
    });
    $('.navbar-close').click(function() {
      //$('body').removeClass('push-menu-body');
      //$('.navbar').removeClass('push-menu-visible');
      //$('.backdrop').remove();
    });
    $('.navbar-nav').click(function() {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
      //$('.backdrop').remove();
    });
  }


  logout() {
    this.spinner.show();
    setTimeout(() => {
    (window as any).fcWidget.destroy();

    ///Note on remove Cookies or Storage:- What code you going to apply here for remove Keys, Must apply the same code in(app.component, auth.interceptor)
    this.cookieService.delete('submissionId', '/');
    this.cookieService.delete('uType', '/');
    this.cookieService.delete('userId', '/');
    localStorage.removeItem('isLoginSuccess');
    sessionStorage.removeItem('sessionId');
    sessionStorage.clear();
    localStorage.clear();

    this.oauthService.logOut();
    this.spinner.hide();
    }, 1000);
  }

  checkLoginFired()
  {
    if(this.router.url=='/security-question') 
    {
      localStorage.setItem('loginFired', 'true'); 
      this.modalService.open(this.LoginBackError, this.modalOptions);
    }
    else
    this.azureAdlogin();
  }

  redirectLogin()
  {
    this.modalService.dismissAll();
    this.azureAdlogin();
  }

  stopNavigation(){this.modalService.dismissAll();}

  azureAdlogin() {
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }
    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey
    });
  }

  callbackPopup() {
    this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
  }

  callback() {
    this.callbackErrorMsg = '';
    this.callbackSuccessMsg = '';
    this.communicationService.userCallback().subscribe((data: any) => {
      this.callbackSuccessMsg = 'Our coach will contact you shortly';
    },
      (err: HttpErrorResponse) => {
        this.callbackErrorMsg = 'Error: Please try after some time';
      });
  }

}

