import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { RegExValidation } from '../helpers/constants/regex-validation.constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OtpService } from '../services/otp.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../services/data.service';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { PlatformLocation } from '@angular/common';
import { Enum } from '../helpers/enums/enum';
import { Guid } from 'guid-typescript';
import * as $ from 'jquery';
import { interval, Subscription, timer } from 'rxjs';




@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  host: {'class': 'min-h'},
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  @ViewChild('otpmodal', { static: false }) otpmodal: ElementRef;
  modalOptions: NgbModalOptions;
  otpForm; password;
  errorMsg = '';
  otpErrorMsg = '';
  sendOtpText = 'Send OTP';
  successMsg = '';
  usedOtpType;
  messageLinkTemplet=`<a id='messagelink' href='routeValue' onclick='return false;'>linkText</a>`;
  passwordLen: any; passwordDig: any; passwordLow: any; passwordUp: any; passwordSpecial: any;
  disableOTPSendButton: boolean = false;
  secondsLeft;
  countDown: number = 30;
  otpSentSuccessfully: boolean = false;
  sendOtpTextButton: string = 'Send OTP';
  heading: string;
  subHeading: string;
  hideResendOTPButton: boolean = false;
  optSessionStillValid: boolean = false;
  otpSentSuccesfully: number;

  numbersubs: Subscription;
  sessionTimeOut: Subscription;
  isNumberSubSubscribed: boolean = false;
  isSessionTimeOutSubscribed: boolean = false;
  otpSessionTimeOutInterval;
  otpSession10MinInterval;

  counter = 10;
  tick = 1000;
  successMsgWithPipe: boolean = false;

  constructor(
    private otpService: OtpService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    public router: Router,
    private location: PlatformLocation,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private oauthService: OAuthService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    location.onPopState(() => this.modalService.dismissAll());
  }



  ngOnInit() {

    this.heading = "Request an OTP ";
    this.subHeading = "To reset your password, please enter the cellphone number linked to your account. We will send you an OTP to reset your password.";
      localStorage.removeItem('tempToken');
      this.otpForm = this.formBuilder.group({
        SearchText: ['', [Validators.required]],
        Otp: ['', [Validators.required, Validators.minLength(6)]],
        Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
        ConfirmPassword: ['', Validators.required]
      }, {
        validator: [
          InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
        ],
      });

      setTimeout(() => {
      if(this.oauthService.getIdentityClaims()){
        this.oauthService.logOut()
      }
    }, 1000);
    this.registerHeight();
  }

  registerHeight() {
    var headerh = $("header").height(),
    windowh = $(window).height(),
    desiredHeight = windowh,
    forgotheight = windowh - headerh - 80;
    $('.min-h').css("min-height", desiredHeight + 'px');
    $('.forgothight').css("min-height", forgotheight + 'px');
  }
  onResize(ev) {
    var headerh = $("header").height(),
    windowh = $(window).height(),
    desiredHeight = windowh,
    forgotheight = windowh - headerh - 80;
    $('.min-h').css("min-height", desiredHeight + 'px');
    $('.forgothight').css("min-height", forgotheight + 'px');
  }

  get otpFormControls() { return this.otpForm.controls; }


  sendOtp() {
  
    this.successMsg = '';
    this.otpErrorMsg = '';
    const searchText = 'SearchText';
    if (this.otpForm.controls[searchText].value === '') {
      this.otpForm.controls[searchText].markAllAsTouched();
      return;
    }
 
    const param = { SearchText: this.otpForm.value.SearchText };

    if (!RegExValidation.cellNumber.test(param.SearchText)) {
      this.otpErrorMsg = 'Please enter valid input.';
      return;
    }

    this.usedOtpType = Enum.OtpType.Sms;

    this.otpService.forgotPasswordSendOtp(param).subscribe((data: any) => { 
  
      if (data.sendOtpStatusType.status === 200) {

        if (data.sendOtpStatusType.message === "SecondOTPSent") 
        {
           this.hideResendOTPButton = true;
        }
        if(this.sendOtpText === 'Send OTP')
        {
          setTimeout(() => {
            setTimeout(() => {
              window.location.reload();
            }, 8000);
           
           
            this.otpErrorMsg = 'OTP session has expired, please try again.'
          }, 600000);

          if(this.otpSession10MinInterval != undefined)
          {
            clearTimeout(this.otpSession10MinInterval);
          }
          if(this.isNumberSubSubscribed)
          {
            this.secondsLeft = 0;
            this.numbersubs.unsubscribe();
          }
          this.disableOTPSendButton = true;
  
          let numbers = interval(1000);
          this.numbersubs = numbers.subscribe(x => {
            this.isNumberSubSubscribed = true;
            this.secondsLeft = this.countDown - x;
            if (this.secondsLeft == 0) {
              this.numbersubs.unsubscribe();
              this.isNumberSubSubscribed = false;
              this.disableOTPSendButton = false;
            }
          });
        }

        


        this.otpSentSuccessfully = true;
        localStorage.setItem('tempToken', data.token);
       
        if (this.sendOtpText === 'Resend OTP') {
          this.successMsg = 'Resend OTP sent successfully';
          this.disableOTPSendButton = true;
          this.hideResendOTPButton = true; 
        }

        this.sendOtpText = 'Resend OTP';
        this.heading = "Enter OTP and new password";
        this.subHeading = "Enter the OTP sent to your cellphone number and set your new password.";
      }

      if(data.sendOtpStatusType.status === 202)
      {
        this.otpErrorMsg = 'OTP already verified, please try later.';
      }
    },
      (err: HttpErrorResponse) => {
      
        if (err.status === 422) {
          // tslint:disable-next-line: max-line-length
          this.otpErrorMsg = 'You first need to complete registration process by clicking on {REGISTER-LINK}';
          this.createMessageLink();
        }
        else if (err.status === 501) {
          // tslint:disable-next-line: max-line-length
          this.otpErrorMsg = 'We can not find your contact details. If you have not already registered please go to the registration page, alternatively call one of our coaches for assistance.';
        }

        if(err.error.sendOtpStatusType.status===421){     
          if(err.error.sendOtpStatusType.sessionTimeOut > 0){
            localStorage.setItem('tempToken', err.error.token);
            this.successMsg = "An OTP has been sent to your cellphone number."
            
            this.otpSessionTimeOutInterval =     setTimeout(() => {
              this.counter = err.error.sendOtpStatusType.sessionTimeOut;
              this.sessionTimeOut = timer(0, this.tick).
              subscribe(() => {
                --this.counter;
                if(this.counter === 0){                 
                  this.sessionTimeOut.unsubscribe();
                  window.location.reload();   
                }
              });
          
              this.successMsgWithPipe = true;
              this.successMsg = '';

            }, 2000);

            this.hideResendOTPButton = true;
            this.sendOtpText = "Resend OTP" ;
            this.optSessionStillValid = true;
            this.otpSentSuccessfully = true; 
          }
          else{
            this.otpErrorMsg = "OTP Already Sent. Please try after later";
           }
        } 
        else if (err.error.sendOtpStatusType.status === 429) {
          this.otpErrorMsg = 'OTP failed, please try again.';
        }
        else{
          this.otpErrorMsg = 'Please contact admin';  
        }
      });
  }


  resetOtpToDefault(){
    if(this.otpSession10MinInterval != undefined){
      clearTimeout(this.otpSession10MinInterval);
    }
    if(this.otpSessionTimeOutInterval != undefined){
      clearTimeout(this.otpSessionTimeOutInterval);
    }
    if(this.isSessionTimeOutSubscribed){
      this.sessionTimeOut.unsubscribe();
    } 
    if(this.isNumberSubSubscribed)
    {
      this.numbersubs.unsubscribe();
    }
    this.successMsgWithPipe = false;
    this.optSessionStillValid = null;
  }


  forgot() {
    this.otpErrorMsg = '';
    this.successMsg = '';
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      return;
    }

    const param = {
      Password: this.otpForm.value.Password,
      Otp: parseInt(this.otpForm.value.Otp, 10),
      OtpType: this.usedOtpType
    };

    this.otpService.forgotPasswordUpdate(param).subscribe((data: any) => {

      this.cookieService.delete('OTPSendStatus');
      this.cookieService.delete('OTPSentSuccessfully');
      this.successMsg = 'Password updated successfully';
      this.otpForm.reset();
      this.otpForm.disable();
      this.sendOtpText = 'Send OTP'; 
      this.resetOtpToDefault();
      setTimeout(() => {
        this.azureAdlogin();
      }, 1000);
    },
      (err: HttpErrorResponse) => {

        if (err.status === 422) {
          this.otpErrorMsg = 'Incorrect OTP.';
        }  
        else if(err.status === 403){
          this.otpErrorMsg = "OTP session has expired, please try later."
         setTimeout(() => {
            window.location.reload();
          }, 3000);
        
          
        }else{
          this.otpErrorMsg = 'Please contact admin';  
        }
      });
  }
  
  azureAdlogin() {
    localStorage.removeItem('tempToken');
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }
    const submissionCode = Guid.create().toString();
    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin, submissionCode,
      instrumentationKey: environment.appInsights.instrumentationKey
    });
  }

  handleKeyDown(event: any) {
    if (event.keyCode === 13) {
       this.sendOtp();
    }
  }

  createMessageLink(){
    if(this.otpErrorMsg.includes('{REGISTER-LINK}'))
    {
      this.messageLinkTemplet=this.messageLinkTemplet.replace('linkText','REGISTER').replace('routeValue','/register');
      this.otpErrorMsg=this.otpErrorMsg.replace('{REGISTER-LINK}',this.messageLinkTemplet);
    }
  }

  classPwrd: boolean = false;
  pwrdTextType: boolean;
  pwrdToggle(event: any) {
    this.classPwrd = !this.classPwrd ;
    this.pwrdTextType = !this.pwrdTextType;
  }
  classConfirmPwrd: boolean = false;
  conpwrdTextType : boolean;
  confirmpwrdToggle(event: any) {
    this.classConfirmPwrd = !this.classConfirmPwrd ;
    this.conpwrdTextType = !this.conpwrdTextType;
  }

  popOverOpened(){
    if(this.password != null){
      const variations = {
        digits: /\d/.test(this.password),
        lower: /[a-z]/.test(this.password),
        upper: /[A-Z]/.test(this.password),
        nonWords: /[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#]/.test(this.password),
        length: this.password.length >= 8,
      };

      var popover = document.getElementById('ngb-popover-0');
          if(popover != null){
            if (variations.length) {
              var passwordLen = document.getElementById('password-length');
              var lengthSp = document.getElementById('password-length-sp');
              passwordLen.classList.add('password-requirement-matched');
              lengthSp.classList.add('tick-mark');
            }
            if (variations.digits) {
              var passwordDig = document.getElementById('password-digit');
              var DigitSp = document.getElementById('password-digit-sp');
              passwordDig.classList.add('password-requirement-matched');
              DigitSp.classList.add('tick-mark');
            }
            if (variations.lower) {
              var passwordLow = document.getElementById('password-lowercase');
              var LowerSp = document.getElementById('password-lowercase-sp');
              passwordLow.classList.add('password-requirement-matched');
              LowerSp.classList.add('tick-mark');
            }
            if (variations.upper) {
              var passwordUp = document.getElementById('password-uppercase');
              var UpperSp = document.getElementById('password-uppercase-sp');
              passwordUp.classList.add('password-requirement-matched');
              UpperSp.classList.add('tick-mark');
            }
            if (variations.nonWords) {
              var passwordSpecial = document.getElementById('password-specialchar');
              var SpecialSp = document.getElementById('password-specialchar-sp');
              passwordSpecial.classList.add('password-requirement-matched');
              SpecialSp.classList.add('tick-mark');
            }
          }
    }
    
  }

  PassVal(){
    if(this.password == ''){
      var passwordDig = document.getElementById('password-digit');
      var DigitSp = document.getElementById('password-digit-sp'); 
      var passwordLow = document.getElementById('password-lowercase');
      var LowerSp = document.getElementById('password-lowercase-sp');
      var passwordUp = document.getElementById('password-uppercase');
      var UpperSp = document.getElementById('password-uppercase-sp');    
      var passwordSpecial = document.getElementById('password-specialchar');
      var SpecialSp = document.getElementById('password-specialchar-sp');
      var passwordLen = document.getElementById('password-length');
      var lengthSp = document.getElementById('password-length-sp');

      passwordLen.classList.remove('password-requirement-matched');
      lengthSp.classList.remove('tick-mark');     
      passwordDig.classList.remove('password-requirement-matched');
      DigitSp.classList.remove('tick-mark');
      passwordLow.classList.remove('password-requirement-matched');
      LowerSp.classList.remove('tick-mark');
      passwordUp.classList.remove('password-requirement-matched');
      UpperSp.classList.remove('tick-mark');
      passwordSpecial.classList.remove('password-requirement-matched');
      SpecialSp.classList.remove('tick-mark');
     
    }
  }

}
