import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CreditInsightService } from '../services/credit-insight.service';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-credit-insights',
  templateUrl: './credit-insights.component.html',
  styleUrls: ['./credit-insights.component.css']
})

export class CreditInsightsComponent implements OnInit {
  creditInsightModel: any = {};
  constructor(private creditInsightService: CreditInsightService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.GetCreditScoreGroupDetails();
    }, 500);
  }
  
  GetCreditScoreGroupDetails() {

    var data = sessionStorage.getItem('creditInsightModel');
    if (data != null) {
      this.creditInsightModel = JSON.parse(data);
    }
    else {
      this.creditInsightService.GetCreditInsightDetails().subscribe((data: any) => {
        this.creditInsightModel = data.creditInsightModel;
        sessionStorage.setItem('creditInsightModel', JSON.stringify(this.creditInsightModel));
      },
        (err: HttpErrorResponse) => {
        }
      );
    }
  }
  onResize(_evt: any) { }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 900,
    navText: ['', ''],
    startPosition: 0,
    nav: false,
    autoplay: false,
    autoplayHoverPause: false,
    stagePadding:80,  // Adjust padding if necessary
    margin:10,
    smartSpeed:450,
    responsiveRefreshRate : 1000,
    //autoHeight:true,
    autoWidth: true, // Add autoWidth
    responsive: {
      0: {
        items: 2.4
      },
      400: {
        items: 2.4
      },
      740: {
        items:2.4
      },
      940: {
        items:2.4
      }
    },
  };
  // disabledAutoplay(){
  //   this.customOptions.autoplay = false;
  //   this.customOptions.touchDrag =  true;
  //   this.customOptions.pullDrag=false;
  //   this.customOptions.navSpeed= 900

  // }

}
