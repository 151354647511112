import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js';
import * as $ from 'jquery';
import { DashboardService } from '../services/dashboard.service';
import { SessionService } from '../services/active-session-management.service';
@Component({
  selector: 'app-credit-score-history',
  templateUrl: './credit-score-history.component.html',
  styleUrls: ['./credit-score-history.component.css']
})
export class CreditScoreHistoryComponent implements OnInit {
  @Input() item = '';
  enableGraph: boolean = true;
  monthList: any;
  scoreList: any;
  scoreGraphData;
  progressIndicator: number;
  text;
  progressData;
  cssClass;
  display: boolean = false;
  hasData: boolean = false;
  registrationDate;
  constructor(private dashboardService: DashboardService,private sessionService:SessionService) { }

  ngOnInit() {
    if (!localStorage.getItem('flag')) {
      localStorage.setItem('flag', 'true');
      this.sessionService.setActiveSession();
    }
  
    this.sessionService.checkActiveSession();
    setTimeout(() => {
      this.getHistoricCreditScoreByIdnumber();
    }, 1000);
  }

  graphIntegration(scoreList, monthList, ctx) {
    let data: any,
      options: any,
      chart: any,
      d = new Date();

    const gradient = ctx.getContext('2d').createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, 'rgba(74, 174, 172, 0.49)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    data = {
      labels: monthList,
      datasets: [
        {
          label: 'Apples',
          data: scoreList,
          backgroundColor: gradient,
          borderColor: 'rgba(74, 174, 172, 1)',
          pointBackgroundColor: 'rgba(74, 174, 172, 1)',
          fill: true,
          lineTension: 0,
          radius: 5,
        }
      ],
    };

    options = {
      responsive: true,
      layout: {
        padding: {
          right: 24
        }
      },
      scaleFontColor: "#FFFFFF",
      maintainAspectRatio: false,
      title: {
        display: false,
        maintainAspectRatio: false,
      },
      legend: {
        display: false,
        fontColor: "white",
        labels: {
          // This more specific font property overrides the global property
          fontColor: 'white',
          scaleFontColor: "#2B373F",
        }
      },
      tooltips: {
        borderWidth: 2,
        borderColor: 'rgba(74, 174, 172, 1)',
        yAlign: 'top',
        xAlign: 'center',
        xPadding: 5,
        yPadding: 5,
        //titleAlign: 'center',
        //footerAlign: 'center',
        Font: { weight: 'bold' },
        bodyAlign: 'center',
        callbacks: {
          label: function (tooltipItem, data) {
            let label = (tooltipItem.xLabel)
            let label2 = (tooltipItem.yLabel);
            return [label, label2];
          }
        },
        titleFontSize: 0,
        displayColors: false,
        bodyFontColor: 'rgba(74, 174, 172, 1)',
        bodyFontSize: 14,
        backgroundColor: '#fff'
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: "#2B373F",
            margin: 0,
            fontSize: 14,
            fontStyle: "normal",
            reverse: true,
          },
          gridLines: {
            color: "red", display: false,
          },
          scaleLabel: {
            fontColor: "#2B373F"
          },
        }],
        yAxes: [{
          display: true,
          ticks: {
            min: 0,
            max: 1000,
            stepSize: 200,
            fontColor: "#2B373F",
            margin: 100,
            fontSize: 14,
            fontStyle: "normal",
          },
          gridLines: {
            color: "rgba(231, 231, 231, 1)",
          },
          scaleLabel: {
            fontColor: "#2B373F"
          }
        }]
      },
    };

    chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });
  }



  getHistoricCreditScoreByIdnumber() {
    var elementName=this.item;
   
    var scoreData = sessionStorage.getItem('creditScore');
    if(scoreData!=null) {
      this.BindCreditScore(JSON.parse(scoreData),elementName); 
      return;
    }

    this.dashboardService.getCreditScoreChart().subscribe((data: any) => {  
      this.BindCreditScore(data,elementName);
      sessionStorage.setItem('creditScore', JSON.stringify(data));
    });
  }
 
  private BindCreditScore(data: any,elementName:string) {

    if (data.creditScoreResponse.length === 0) {
      this.hasData = false;
      this.registrationDate = data.registrationDate;
    } else {
      this.hasData = true;
    }
    var _ct = this;
    this.removeDuplicateObject(data.creditScoreResponse, function (result) {
      _ct.scoreGraphData = result;
      if (_ct.scoreGraphData.length === 0) {
        _ct.enableGraph = false;
      }
      else {
      let ctx: any = document.getElementById(elementName) as HTMLElement

        _ct.monthList = _ct.scoreGraphData.map(a => { return (a.month + " " + a.year.toString().substr(2, 2)); });
        _ct.scoreList = _ct.scoreGraphData.map(a => a.score);
        _ct.progressIndicatcor(_ct.scoreList[0], _ct.scoreList[1]);
        _ct.graphIntegration(_ct.scoreList, _ct.monthList,ctx);
        
      }
    });
  }

  removeDuplicateObject(data, callback) {
    var result = [];
    $.each(data, function (i, e) {
      var matchingItems = $.grep(result, function (item) {
        return item.year === e.year && item.month === e.month;
      });
      if (matchingItems.length === 0) {
        result.push(e);
      }
    });
    callback(result);
  }


  progressIndicatcor(lastestScore: number, secondLastest: number) {


    let val = lastestScore - secondLastest;
    this.progressIndicator = (val < 0) ? (val * -1) : val;


    if (val > 0) {
      this.text = "improved since last month"
      this.cssClass = "progreesIndicator-up"
    } else if (val === 0) {
      this.text = "since last month"
    } else {
      this.text = "decreased since last month"
      this.cssClass = "progreesIndicator-down"
    }




    this.progressData = {
      realValue: this.progressIndicator,
      text: this.text,
      cssClass: this.cssClass
    };
    this.display = true;
  }
} 
