import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ChatService } from '../services/chat.service';
import { HttpErrorResponse } from '@angular/common/http';
import { JsonPipe } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  host: {'class': ''},
})
export class LayoutComponent implements OnInit {
  env = environment;
  userData = null;

  constructor(
    private cookieService: CookieService,
    private chatService: ChatService,
    private oauthService: OAuthService
  ) { }

  // restoreId: any;
  ngOnInit() {
    this.userData = this.oauthService.getIdentityClaims();
    if (!this.userData) {
      return;
    }

    const requestParams = {
       firstName: this.userData.given_name,
       idNumber: this.userData.name,
       surname: this.userData.family_name
      };
    if ((window as any).fcWidget) {
      // (window as any).fcWidget.init({
      //   token: this.env.freshChatToken, host: this.env.freshChatHost,
      //   firstName: requestParams.firstName, externalId: requestParams.idNumber, lastName: requestParams.surname
      // });

      (window as any).fcWidget.user.get((resp) => {

        if (resp.status !== 200) {
       

          (window as any).fcWidget.user.setProperties({
            firstName: requestParams.firstName,
            lastName: requestParams.surname
          });

          (window as any).fcWidget.on('user:created', (resp1) => {
       
            const requestTime = new Date();

            if (resp1.status === 200) {
           
              (window as any).fcWidget.user.get((resp) => {
                const param = {
                  Endpoint: window.location.href,
                  IdNumber: this.userData.idNumber,
                  RequestParam: JSON.stringify(requestParams),
                  RequestTime: new Date(),
                  ResponseData: JSON.stringify(resp),
                  ResponseTime: new Date()
                };

                this.chatService.saveChat(param).subscribe((data: any) => {

                }, (err: HttpErrorResponse) => {
                });

              });

            }
          });
        }


      });
    }
  }

}
