import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-navigation-popup',
  templateUrl: './navigation-popup.component.html',
  styleUrls: ['./navigation-popup.component.css'],
  host: { 'class': 'navigationPopheight d-flex w-100 align-items-center flex-wrap' },
})
export class NavigationPopupComponent implements OnInit {
  isPopupShown: boolean = false;
  constructor() { }

  ngOnInit(): void {
    const popupState = sessionStorage.getItem('popupShown');
    if (popupState === 'true') {
      this.isPopupShown = true;
    }

    if (!this.isPopupShown) {
      this.carouselNormalization();
      this.showPopup();
    }
  }
  showPopup(): void {
    this.isPopupShown = true;
    sessionStorage.setItem('popupShown', 'true');
  }
  carouselNormalization() {
    var windowh = $(window).height(),
    desiredHeight = windowh;
    $('.navigationPopheight').css("min-height", desiredHeight + 'px');
  }
}
