import { Directive, ElementRef, HostListener } from '@angular/core';
import { RegExValidation } from '../constants/regex-validation.constants';

@Directive({
  selector: '[name-field-with-space]'
})
export class NameFieldWithSpaceDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initalValue.replace(RegExValidation.textWithSpace, '');

    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
