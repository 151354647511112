import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';
import { InputValidationService } from 'src/app/helpers/validators/input-validation.service';
import { RegExValidation } from 'src/app/helpers/constants/regex-validation.constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OtpService } from 'src/app/services/otp.service';
import { PlatformLocation } from '@angular/common';
import { RegistrationRoutingService } from 'src/app/services/registration-routing.service';
import { CookieService } from 'ngx-cookie-service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Subscription, interval, timer } from 'rxjs';

@Component({
  selector: 'app-register-step-two',
  templateUrl: './register-step-two.component.html',
  styleUrls: ['./register-step-two.component.css'],
  host: { 'class': 'd-flex flex-column h-100 register-bg' }
})
export class RegisterStepTwoComponent implements OnInit {
  @ViewChild('otpModel', { static: false }) otpModel: ElementRef;
  @ViewChild('captureIncomeModel', { static: false }) captureIncomeModel: ElementRef;

  modalOptions: NgbModalOptions;

  registerForm; otpForm; autoRegisterForm;
  errorMsg = ''; securityQuestionErrorMsg = ''; signupSuccess = false;
  otpErrorMsg = '';
  password = '';
  cellNumberErrorMsg = '';
  sendOtpText = 'Send SMS OTP';
  sendEmailOtpText = 'Send Email OTP';
  otpPopupHeaderText = 'Get your One-Time Pin (OTP) via SMS';
  securityQuestionList = [];
  basicInfoVerifyConfirmed: any;
  isRestrictedUser: any;
  securityQuestionAttempt = 1;
  successMsg = '';
  time;
  btnOTPIsDisabled = false;
  usedOtpType;
  isOtpSent = false;
  modelWindow = 'question';
  cancelQuestion = false;
  IdNumber = null;
  messageLinkTemplet = `<a id='messagelink' href='routeValue' onclick='return false;'>linkText</a>`;
  WebSource = '';
  RestQueryString = '';
  IsAutoRegister = '0';
  disableOTPSendButton: boolean = false;
  secondsLeft;
  countDown: number = 30;
  otpSentSuccessfully: boolean = false;
  hideResendOTPButton: boolean = false;

  optSessionStillValid: boolean | null = false;
  otpSentSuccesfully: number;

  numbersubs: Subscription;
  sessionTimeOut: Subscription;
  isNumberSubSubscribed: boolean = false;
  isSessionTimeOutSubscribed: boolean = false;
  otpSessionTimeOutInterval;
  otpSession10MinInterval;
  secLeft : number;

  counter : number;
  tick = 1000;
  successMsgWithPipe: boolean = false;
  resendAvailable: boolean = null;
  

  constructor(
    private userService: UserService,
    private otpService: OtpService,
    public router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private registrationRouting: RegistrationRoutingService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    location.onPopState(() => this.modalService.dismissAll());
    this.titleService.setTitle('JustMoney');
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params && (params.websource || params.WebSource || params.WEBSOURCE|| params.webSource)) {
        this.WebSource = params.websource || params.WebSource || params.WEBSOURCE|| params.webSource;
        this.RestQueryString = this.registrationRouting.queryStringParams();
      }
    });

    if (localStorage.getItem('IdNumber') != '' && localStorage.getItem('IdNumber') != null) {
      this.IdNumber = localStorage.getItem('IdNumber');
    }

    if (localStorage.getItem('IsAutoRegister') != '' && localStorage.getItem('IsAutoRegister') != null) {
      this.IsAutoRegister = localStorage.getItem('IsAutoRegister');
    }


    this.autoRegisterForm = this.formBuilder.group({
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      NewsLetter: [true],
      DbcUserType: 2,
      WebSource: '',
      Params: ''
    },
      {
        validator: [
          InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
        ],
      });

    localStorage.removeItem('tempToken');
    this.registerForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, InputValidationService.isWhiteSpace]],
      Surname: ['', [Validators.required, InputValidationService.isWhiteSpace]],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
      Email: ['', [Validators.required, Validators.email, InputValidationService.isValidEmail]],
      IdNumber: this.IdNumber,
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      NewsLetter: [true],
      DbcUserType: 2,
      WebSource: '',
      Params: ''
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });

    this.otpForm = this.formBuilder.group({
      Otp: ['', [Validators.required, Validators.minLength(6)]],
    });


    this.bindNavBar();

    this.registerHeight();

    setTimeout(() => {
      if (this.oauthService.getIdentityClaims()) {
        this.oauthService.logOut();
      }
    }, 1000);

    var queryParams = this.registrationRouting.queryStringParams();
    if(queryParams){
      localStorage.setItem('UtmParams', queryParams);
    }
  }


  registerHeight() {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh;
    $('.min-h').css('min-height', desiredHeight + 'px');

  }
  onResize(ev) {
    var headerh = $('header').height(),
      footerh = $('footer').height(),
      windowh = $(window).height(),
      desiredHeight = windowh - footerh - headerh;
    $('.min-h').css('min-height', desiredHeight + 'px');
  }

  bindNavBar() {
    const backDrop = '<div class="backdrop"></div>';
    $('.navbar-toggler').click(function () {
      $('body').toggleClass('push-menu-body');
      $('.navbar').toggleClass('push-menu-visible');
    });
    $('.navbar-close').click(function () {
    });
    $('.navbar-nav').click(function () {
      $('body').removeClass('push-menu-body');
      $('.navbar').removeClass('push-menu-visible');
    });
  }

  get registerFormControls() { return this.registerForm.controls; }
  get otpFormControls() { return this.otpForm.controls; }
  get autoRegisterFormControls() { return this.autoRegisterForm.controls; }

  startTimeOut() {
    window.onload = () => {
      this.resetTimer();
    };

    document.onmousemove = () => {
      this.resetTimer();
    };

    document.onkeypress = () => {
      this.resetTimer();
    };

  }

  resetTimer() {
    if (this.time !== 'stop') {
      
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        
        this.modalService.dismissAll();
        this.resetRegisterForm();
        if (this.modelWindow === 'otp') {
          this.errorMsg = 'Your session is over. Please click on {REGISTER-LINK}, to start process again.';
        } else {
          this.errorMsg = 'Your session has timed-out and your registration could not be completed. Please click {REGISTER-LINK} to start process again.';
        }

        localStorage.setItem('errorMsg', this.errorMsg);

        this.showIdNumberForm();
      }, 10 * 60 * 1000);
    }
  }

  stopTimer() {
    clearTimeout(this.time);
    this.time = 'stop';
    console.log('Timer Stop');
    console.log(this.time);
  }

  resetOtpToDefault(){
    if(this.otpSession10MinInterval != undefined){
      clearTimeout(this.otpSession10MinInterval);
    }
    if(this.otpSessionTimeOutInterval != undefined){
      clearTimeout(this.otpSessionTimeOutInterval);
    }
    if(this.isSessionTimeOutSubscribed){
      this.sessionTimeOut.unsubscribe();
    } 
    this.successMsgWithPipe = false;
    this.optSessionStillValid = null;
  }

  autoRegister() {
    console.log("auto register");
    if (this.autoRegisterForm.invalid) {
      this.autoRegisterForm.markAllAsTouched();
      return;
    }

    const payload = {
      IdNumber: this.IdNumber,
      Password: this.autoRegisterForm.controls.Password.value,
      ConfirmPassword: this.autoRegisterForm.controls.ConfirmPassword.value,
      NewsLetter: this.autoRegisterForm.controls.NewsLetter.value,
      WebSource: this.WebSource,
      Params:  localStorage.getItem('UtmParams'),
    }
    console.log(payload);
    this.userService.autoRegisterUser(payload).subscribe((data: any) => {
      console.log(data);
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        this.resetOtpToDefault();
        this.validateUserBasicInfoWithBureau();

        this.registerForm.patchValue({
          PhoneNumber: data.phoneNumber
        });
        this.openOtpPopup();
        this.startTimeOut();
        setTimeout(() => localStorage.removeItem('UtmParams'), 300);
      }
      else {
        $('.login-button').hide();
        this.errorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        //this.hideLogin();
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.errorMsg = err.error;
          localStorage.setItem('errorMsg', this.errorMsg);
          this.showIdNumberForm();
        }
      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  register() {
    localStorage.removeItem('tempToken');
    this.errorMsg = '';
    this.otpErrorMsg = '';
    this.cellNumberErrorMsg = '';
    this.securityQuestionErrorMsg = '';
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }

    this.registerForm.patchValue({
      FirstName: this.registerForm.controls.FirstName.value.replace(RegExValidation.textName, ''),
      Surname: this.registerForm.controls.Surname.value.replace(RegExValidation.textWithSpace, ''),
      PhoneNumber: this.registerForm.controls.PhoneNumber.value.replace(RegExValidation.numeric, ''),
      IdNumber: this.IdNumber,
      WebSource: this.WebSource,
      Params:  localStorage.getItem('UtmParams')
    });

    console.log("register", this.registerForm.value);

    this.userService.register(this.registerForm.value).subscribe((data: any) => {
      if (data.status === 200) {
        localStorage.setItem('tempToken', data.token);
        this.resetOtpToDefault();
        this.validateUserBasicInfoWithBureau();
        this.openOtpPopup();
        this.startTimeOut();
        setTimeout(() => localStorage.removeItem('UtmParams'), 300);
      } else {
        this.errorMsg = data.message;
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.errorMsg = err.error.join('<br>');
        } else if (err.status === 410) {
          this.cellNumberErrorMsg = err.error;
        } else {
          this.errorMsg = err.error;
          localStorage.setItem('errorMsg', this.errorMsg);
          this.showIdNumberForm();
        }

      });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  dbcUserBasicInfoVerify() {
    this.userService.dbcUserBasicInfoVerify().subscribe((data: any) => {
      this.basicInfoVerifyConfirmed = true;
    },
      (err: HttpErrorResponse) => {
      });
  }

  resetRegisterForm() {
    this.registerForm.reset();
    this.registerForm.markAsUntouched();
    this.registerForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, InputValidationService.isWhiteSpace]],
      Surname: ['', [Validators.required, InputValidationService.isWhiteSpace]],
      PhoneNumber: ['', [Validators.required, Validators.minLength(10), InputValidationService.isValidMobile]],
      Email: ['', [Validators.required, Validators.email, InputValidationService.isValidEmail]],
      IdNumber: this.IdNumber,
      Password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(RegExValidation.password)]],
      ConfirmPassword: ['', Validators.required],
      AcceptTerms: [false, Validators.pattern('true')],
      NewsLetter: [true],
      DbcUserType: 2
    }, {
      validator: [
        InputValidationService.passwordMustMatch('Password', 'ConfirmPassword'),
      ],
    });
  }

  validateUserBasicInfoWithBureau() {
    this.otpErrorMsg = '';
    this.basicInfoVerifyConfirmed = false;
    this.userService.validateUserBasicInfoWithBureau().subscribe((data: any) => {   
      if (data.isVerify === true) {
        this.basicInfoVerifyConfirmed = true;
        this.isRestrictedUser=false;
      } else {
        this.basicInfoVerifyConfirmed = false;
        this.isRestrictedUser=data.isRestrictedUser;
      }
      console.log(this.basicInfoVerifyConfirmed);
    },
      (err: HttpErrorResponse) => {
      });
  }

  openOtpPopup() {
    this.sendOtpText = 'Send SMS OTP';
    this.sendEmailOtpText = 'Send Email OTP';
    this.isOtpSent = false;
    this.successMsg = '';
    this.otpErrorMsg = '';
    this.otpForm.markAsUntouched();
    this.modelWindow = 'otp';
    this.modalService.open(this.otpModel, this.modalOptions);
  }

  sendOtp(otpType) {
    this.otpErrorMsg = '';
    this.otpForm.reset();
    const param = {
      OtpType: otpType,
    };

    this.usedOtpType = otpType;
    this.otpService.sendOtps(param).subscribe((data: any) => {

      this.isOtpSent = true;
      this.otpPopupHeaderText = 'Your OTP has been sent to you, please enter it below';
      if (data.sendOtpStatusType.status === 200) {
          if (data.sendOtpStatusType.message === "SecondOTPSent") 
          {
             this.hideResendOTPButton = true;
             this.resendAvailable = false;
          }
          if(data.sendOtpStatusType.message === "Sent")
          {
                
                this.resendAvailable = true;
                this.hideResendOTPButton = false;
                if(this.otpSession10MinInterval != undefined)
                {
                  clearTimeout(this.otpSession10MinInterval);
                }
                if(this.isNumberSubSubscribed)
                {
                  this.secondsLeft = 0;
                  this.numbersubs.unsubscribe();
                }
                
              this.disableOTPSendButton = true;
  
              let numbers = interval(1000);
              this.numbersubs = numbers.subscribe(x => {
                this.isNumberSubSubscribed = true;
                this.secondsLeft = this.countDown - x;
                if (this.secondsLeft == 0) {
                  this.numbersubs.unsubscribe();
                  this.isNumberSubSubscribed = false;
                  this.disableOTPSendButton = false;
                }
              });
          }
          this.otpSession10MinInterval = setTimeout(() => {

            this.successMsgWithPipe = false;          
            if(this.isSessionTimeOutSubscribed){
              this.sessionTimeOut.unsubscribe();
            }  
            this.otpErrorMsg = 'OTP session has expired, please try again.'
            setTimeout(() => {
              window.location.href = localStorage.getItem("Url");
            }, 5000);
          }, 600000);


        }
        
        if (this.sendOtpText === 'Resend SMS OTP') {

          this.successMsg = 'Resend OTP sent successfully';
          this.disableOTPSendButton = true;
          this.hideResendOTPButton = true; 
          setTimeout(() => {
            this.successMsg = '';
          }, 3000);
        }

      this.sendOtpText = 'Resend SMS OTP';
      if(data.sendOtpStatusType.status === 202)
      {
        this.otpErrorMsg = 'OTP already verified, please try later.';
      }


    },
      (err: HttpErrorResponse) => {
        if(err.error.sendOtpStatusType.status===421){

          if(err.error.sendOtpStatusType.sessionTimeOut > 0){

           
            if(this.isSessionTimeOutSubscribed)
            {
              this.counter = 0;
              this.sessionTimeOut.unsubscribe();
            }
            this.sendOtpText = "Resend SMS OTP";
            this.isOtpSent = true; 
            if(this.resendAvailable)
            {
              this.sendOtpText = 'Resend SMS OTP';
              this.hideResendOTPButton = false;
            }
            else
            {
              this.successMsg = "An OTP has been sent to your cellphone number."
              this.hideResendOTPButton = true;
              this.optSessionStillValid = true;

              this.otpSessionTimeOutInterval =  setTimeout(() => {
                this.counter = err.error.sendOtpStatusType.sessionTimeOut;
                this.sessionTimeOut = timer(0, this.tick).
                subscribe(() => {
                  --this.counter;
                  this.isSessionTimeOutSubscribed = true;
                //  console.log("C " + this.counter);
            
                  this.secLeft = this.counter;
              //    console.log("TF " + this.secLeft);
                  if(this.counter === 0){
                    this.sessionTimeOut.unsubscribe();
                    this.isSessionTimeOutSubscribed = false;       
                    window.location.href = localStorage.getItem("Url");
                  }
                });
  
                this.successMsgWithPipe = true;
                this.successMsg = '';
  
              }, 3000);
            }



          }
          else{
            this.otpErrorMsg = "OTP Already Sent. Please try after later";
           }
        } 
      });
  }

  validateOtp() {
    if (this.btnOTPIsDisabled) {
      return;
    }
    this.btnOTPIsDisabled = true;
    this.otpErrorMsg = '';
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      this.btnOTPIsDisabled = false;
      return;
    }
    const param = {
      Otp: parseInt(this.otpForm.value.Otp),
      OtpType: this.usedOtpType
    };

    this.otpService.validateOtps(param).subscribe((data: any) => {
      this.btnOTPIsDisabled = false;
      setTimeout(() => {
        this.closeOtpPopup();
        this.resetOtpToDefault()
        // now check if verify ok and otp ok no need for open security question
        this.checkSecurityQuestionsNeeded();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        this.btnOTPIsDisabled = false;
        if (err.status === 422) {
          this.otpErrorMsg = 'Invalid OTP';
          setTimeout(() => {
            this.otpErrorMsg = '';
          }, 2000);
  
        }else if(err.status === 403){
          this.otpErrorMsg = "OTP session has expired, please try later."
          setTimeout(() => {
            window.location.href = localStorage.getItem("Url");
           }, 3000);
         }else{
           this.otpErrorMsg = 'Please contact admin';  
         }
      });
  }

  checkSecurityQuestionsNeeded(){
    if (this.basicInfoVerifyConfirmed === false ) {
      // now check if restricted user no need for open security question
      if(this.isRestrictedUser==false){
      this.openSecurityQuestions();
      }
      else{
        this.hideSecurityQuestion();
        this.errorMsg = "Your information could not be found and registration could not be completed. Please contact a JustMoney Coach for assistance.";
        $('#id-number-form').show();
        $('#auto-register-form').hide();
        $('#registerForm').hide();
      }
    }else {
      this.dataLayerCallback(true)
      { this.login(); }
    }

  }

  dataLayerCallback(callback: boolean) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'New User Register Successfully'
    });
  }

  closeOtpPopup() {
    this.modalService.dismissAll();
    this.otpForm.reset();
    this.sendOtpText = 'Send SMS OTP';
    this.resendAvailable = null;
    this.sendEmailOtpText = 'Send Email OTP';
    if(this.isNumberSubSubscribed){
      this.numbersubs.unsubscribe();
    }
  }

  openSecurityQuestions() {
    this.modalService.dismissAll();
    localStorage.removeItem('IdNumber');
    localStorage.removeItem('IsAutoRegister'); 
    this.router.navigateByUrl('/security-question');
  }

  login() {
    this.modalService.dismissAll();
    this.stopTimer();
    this.spinner.show();
    this.azureAdlogin('User Registered Successfully');
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  azureAdlogin(message) {
    localStorage.removeItem('tempToken');
    localStorage.removeItem('IdNumber');
    localStorage.removeItem('IsAutoRegister');
    let submissionId = '';
    if (sessionStorage.getItem('submissionId')) {
      submissionId = sessionStorage.getItem('submissionId');
    }

    this.oauthService.initLoginFlow('', {
      submissionId, ccAppUrl: window.location.origin,
      instrumentationKey: environment.appInsights.instrumentationKey, message
    });
  }

  hideSecurityQuestion() {
    this.modalService.dismissAll();
    this.showIdNumberForm();
  }

  showIdNumberForm() {
    localStorage.removeItem('IdNumber');
    localStorage.removeItem('IsAutoRegister');
    
    this.registrationRouting.routeTo(this.WebSource);
    
  }





classPwrd: boolean = false;
pwrdTextType: boolean;
pwrdToggle(event: any) {
   this.classPwrd = !this.classPwrd ;
   this.pwrdTextType = !this.pwrdTextType;
}
classConfirmPwrd: boolean = false;
conpwrdTextType : boolean;
confirmpwrdToggle(event: any) {
  this.classConfirmPwrd = !this.classConfirmPwrd ;
  this.conpwrdTextType = !this.conpwrdTextType;
}


registerclassPwrd: boolean = false;
registerpwrdTextType: boolean;
registerpwrdToggle(event: any) {
   this.registerclassPwrd = !this.registerclassPwrd ;
   this.registerpwrdTextType = !this.registerpwrdTextType;
}

registerclassConfirmPwrd: boolean = false;
registerconpwrdTextType : boolean;
registerconfirmpwrdToggle(event: any) {
  this.registerclassConfirmPwrd = !this.registerclassConfirmPwrd ;
  this.registerconpwrdTextType = !this.registerconpwrdTextType;
}
}
