import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-navigation-popup',
  templateUrl: './navigation-popup.component.html',
  styleUrls: ['./navigation-popup.component.css'],
  host: { 'class': 'navigationPopheight d-flex w-100 align-items-center flex-wrap' },
})
export class NavigationPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.carouselNormalization();
  }
  carouselNormalization() {
    var windowh = $(window).height(),
    desiredHeight = windowh;
    $('.navigationPopheight').css("min-height", desiredHeight + 'px');
  }
}
