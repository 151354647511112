<section class="bg-white pb-4 pt-4 pt-md-5 px-0 b5containeradjust d-block d-md-none" id="appcreditinsight"
*ngIf="creditInsightModel!=null && creditInsightModel?.length>0"
>
    <div class="">

        <div class="col-12">
            <h3 class="fw-bold text-start px-3"> <i> <img src="../../assets/images/stars.png" alt="" /> </i> Improve Your
                Credit Score</h3>
            <p class=" px-3">Personalized insights to improve your credit score.</p>
            <div class="creditInsightsCarousel py-3 ps-3">
                <!-- <owl-carousel-o (window:resize)="onResize($event)" [options]="customOptions">
                    <ng-template carouselSlide [id]="slide.category"
                        *ngFor="let slide of creditInsightModel; let i = index">
                        <a class="d-flex flex-wrap w-100 mt-auto" href="javascript:void(0)" 
                        href="/portal/credit-insight#{{slide.categoryGroup}}"  id="btnCreditScore-{{slide.category}}">
                            <span *ngIf="slide.isTopPriority" class="categoryImageHolder d-block {{slide.uiClassName}}">
                                <span class="category-caption d-flex w-100 align-items-center text-white justify-content-center">
                                    <i class="me-1">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.70798 11.2076C2.41848 11.3561 2.08998 11.0958 2.14848 10.7636L2.77098 7.21611L0.12874 4.69911C-0.11801 4.46361 0.01024 4.03311 0.340989 3.98661L4.01448 3.46462L5.65247 0.219374C5.80022 -0.0731248 6.19997 -0.0731248 6.34772 0.219374L7.98572 3.46462L11.6592 3.98661C11.99 4.03311 12.1182 4.46361 11.8707 4.69911L9.22922 7.21611L9.85171 10.7636C9.91021 11.0958 9.58171 11.3561 9.29222 11.2076L5.99897 9.5156L2.70798 11.2076Z" fill="#ECECEC"/>
                                        </svg>
                                    </i>
                                    HIGH IMPACT
                                </span>
                                <span class="category-Image d-block">
                                    <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                                id="imgCreditScore-{{slide.category}}" />
                                </span>
                            </span>
                            <span *ngIf="!slide.isTopPriority" class="category-Image d-block">
                                <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                            id="imgCreditScore-{{slide.category}}" />
                            </span>
                        </a>
                    </ng-template>
                </owl-carousel-o> -->


                <div class="scroll-slider d-flex">
                    <div class="slide col-4" *ngFor="let slide of creditInsightModel; let i = index">
                        <a class="d-flex flex-wrap w-100 mt-auto" href="javascript:void(0)" 
                        href="/portal/credit-insight#{{slide.categoryGroup}}"  id="btnCreditScore-{{slide.category}}">
                            <span *ngIf="slide.isTopPriority" class="categoryImageHolder d-block {{slide.uiClassName}}">
                                <span class="category-caption d-flex w-100 align-items-center text-white justify-content-center">
                                    <i class="me-1">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.70798 11.2076C2.41848 11.3561 2.08998 11.0958 2.14848 10.7636L2.77098 7.21611L0.12874 4.69911C-0.11801 4.46361 0.01024 4.03311 0.340989 3.98661L4.01448 3.46462L5.65247 0.219374C5.80022 -0.0731248 6.19997 -0.0731248 6.34772 0.219374L7.98572 3.46462L11.6592 3.98661C11.99 4.03311 12.1182 4.46361 11.8707 4.69911L9.22922 7.21611L9.85171 10.7636C9.91021 11.0958 9.58171 11.3561 9.29222 11.2076L5.99897 9.5156L2.70798 11.2076Z" fill="#ECECEC"/>
                                        </svg>
                                    </i>
                                    HIGH IMPACT
                                </span>
                                <span class="category-Image d-block">
                                    <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                                id="imgCreditScore-{{slide.category}}" />
                                </span>
                            </span>
                            <span *ngIf="!slide.isTopPriority" class="category-Image d-block">
                                <img src="{{slide.dashboardImage}}" srcset="{{slide.dashboardImage}}" alt="{{slide.category}}"
                            id="imgCreditScore-{{slide.category}}" />
                            </span>
                        </a>
                    </div>
                    
                  </div>
            </div>
        </div>
    </div>
</section>