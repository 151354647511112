import { Directive, ElementRef, HostListener, Inject, ɵConsole } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { TrackingService } from 'src/app/services/tracking.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from 'src/app/services/appinsights.service';

@Directive({
  selector: 'input,button,textarea,a'
})
export class TrackingDirective {
  env = environment; 
  appInsights: ApplicationInsights;
  userId;
  recordActions : boolean = false;
  isPwa: boolean;

  constructor(private el: ElementRef, private cookieService: CookieService, 
    private trackingService: TrackingService, private appInsightsService: AppInsightsService) {
      if (this.cookieService.get('userId') != null && this.cookieService.get('userId') !== '') {
         this.userId = this.cookieService.get('userId');
      }
      this.isPwa = (sessionStorage.getItem('isPwaActive') === '1') ? true : false;
      this.appInsights = appInsightsService.appInsightsObj(); 
  }


  @HostListener('focus', ['$event']) onFocus(event: any) {
    const initalValue = this.el.nativeElement;
    let isRequired = initalValue.getAttribute('isRequired') == "true" ? true : false;
    const field = initalValue.getAttribute('formControlName');

    if (initalValue.localName === 'input' && initalValue.type != 'checkbox' && initalValue.type != 'radio') {
      if (field === 'Password' || field === 'ConfirmPassword') {
        this.actionTrackEvent(field, isRequired,'enter');
      } else {
        this.actionTrackEvent(field, isRequired,'enter');
      }
    } else if (initalValue.localName === 'textarea') {
      this.actionTrackEvent(field, isRequired,'enter');
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: any) {
    const initalValue = this.el.nativeElement;
    
    let isRequired = initalValue.getAttribute('isRequired') == "true" ? true : false;
    const field = initalValue.getAttribute('formControlName');
    
    if (initalValue.localName === 'input' && initalValue.type != 'checkbox' && initalValue.type != 'radio') {

      if (field === 'Password' || field === 'ConfirmPassword') { 
        if(initalValue.value == null || initalValue.value === ''){     
         this.actionCumFieldTrackEvent(initalValue.localName, field, '', this.userId, isRequired);
        }else{
        this.actionCumFieldTrackEvent(initalValue.localName, field, '*************', this.userId, isRequired);
        }
      } else {
        this.actionCumFieldTrackEvent(initalValue.localName, field, initalValue.value, this.userId, isRequired);
      }
    } else if (initalValue.localName === 'textarea') { 
      this.actionCumFieldTrackEvent('input', field, initalValue.value, this.userId, isRequired);
    }
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    const initalValue = this.el.nativeElement;
   
    if (initalValue.localName === 'button') {


      if (initalValue.name.includes('AccountPage')) {
        this.trackEvent(initalValue.localName, initalValue.name, 'Accounts Data', this.userId, false);
      } else if (initalValue.value.length > 0) {
        this.trackEvent(initalValue.localName, initalValue.name, initalValue.value, this.userId, false);
      } else if(initalValue.type != 'checkbox' && initalValue.type != 'radio') {
        let isClosed = initalValue.getAttribute('isCloseButton') == "true" ? true : false;
        
        if(isClosed){
          this.trackEvent("button", initalValue.name, '', this.userId, false);
        }else{
          this.trackEvent(initalValue.localName, initalValue.name, initalValue.innerText, this.userId, false);
        }
        
      }
    }
    if (initalValue.localName === 'a') {
    
      
      this.trackEvent(initalValue.localName, initalValue.name, initalValue.href, this.userId, false);
      // event.preventDefault(); 
      // setTimeout(function() { location.href = initalValue.href }, 1000);
    }
  }

  @HostListener('change', ['$event']) onChange(event: any) {
    const initalValue = this.el.nativeElement;
    let isRequired = initalValue.getAttribute('isRequired') == "true" ? true : false;
    const field = initalValue.getAttribute('formControlName');
   
   
    if(initalValue.type === 'checkbox'){
      var checkBoxValue = initalValue.checked === true ? "Checked" : "Unchecked";
      this.trackEvent(initalValue.type, field, checkBoxValue, this.userId, isRequired);   
    }else if(initalValue.type === 'radio'){
      this.trackEvent(initalValue.type, initalValue.metadata, initalValue.value, this.userId, false);
    }

  }

  trackEvent(elementType, elementName, elementValue, userId, isRequired) {
    if (this.env.appTrack.isEnable) {
      var fieldParams = {
        FieldType: elementType,  
        FieldName: elementName, 
        FieldMetadata: elementName, 
        FieldValue: elementValue,
        PageName: location.pathname, 
        PageUrl: location.href,
        SubmissionId: sessionStorage.getItem('submissionId'), 
        IsRequired: isRequired,  UserId: this.cookieService.get('userId'),
        IsPwa: this.isPwa
      };
      this.trackingService.field(fieldParams);

      this.trackEventAppInsights(elementType, elementName, elementValue, userId);
    }
  }


  actionCumFieldTrackEvent(elementType, elementName, elementValue, userId, isRequired) {
    if (this.env.appTrack.isEnable) {
      this.isPwa = (sessionStorage.getItem('isPwaActive') === '1') ? true : false;
      var fieldParams = {
        ActionType: 'exit',  FieldType: elementType, FieldName: elementName, FieldMetadata: elementName, FieldValue: elementValue,
        PageName: location.pathname, PageUrl: location.href,
        SubmissionId: sessionStorage.getItem('submissionId'), IsRequired: isRequired, UserId: this.cookieService.get('userId'),
        IsPwa: this.isPwa
      };
     this.trackingService.actionCumField(fieldParams);
 
     this.trackEventAppInsights(elementType, elementName, elementValue, userId);
    }
  }

  actionTrackEvent(elementName, isRequired, action) {
    if (this.env.appTrack.isEnable) {
      this.isPwa = (sessionStorage.getItem('isPwaActive') === '1') ? true : false;
      var fieldParams = {
        ActionType: action, FieldName: elementName, PageName: location.pathname, PageUrl: location.href,
        SubmissionId: sessionStorage.getItem('submissionId'), IsRequired: isRequired,
         UserId: this.cookieService.get('userId'), IsPwa: this.isPwa
      };
      this.trackingService.action(fieldParams);

      this.trackEventAppInsights(action, elementName, '', this.cookieService.get('userId'));
    }
  }

  trackEventAppInsights(elementType, elementName, elementValue, userId) {

    if (this.env.appInsights.isEnable) {

      this.appInsights.trackEvent({
        name: elementType + 'Field',
        properties:  {
          Type: elementType,
          Name: elementName,
          Value: elementValue,
          SubmissionId: sessionStorage.getItem('submissionId'),
          UserId: userId,
          IsPwa: this.isPwa, 
        }
      });


    }
  }

}
