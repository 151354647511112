export namespace Enum {
    export enum CreditScoreStatusType {
        NoScore = 1,
        VeryHighRisk = 2,
        HighRisk = 3,
        MediumHighRisk = 4,
        MediumRisk = 5,
        MediumLowRisk = 6,
        LowRisk = 7,
        VeryLowRisk = 8
    }

    export enum OtpType {
        Sms = 1,
        Email = 2
    }

    export enum LmsCampaignSourceType {
        CreditCoach = 1,
        CreditConsolidation = 2,
        RegistrationFailedSecurityQuestions = 3,
        RegistrationDebtCounselling = 4,
        RegistrationPersonalLoan = 5,
        PersonalLoan = 6,
        Callback = 7,
        DebtConsolidation = 8,
        PersonalLoanDecline = 9,
        RegistrationFailedOTP=10,
        RegistrationFailedSecurityQuestionsIncomplete=11,
        RegistrationSourceCars = 12,
        JustmoneyCreditSav_Solution = 13,
        DebtConsolidationUnderDC = 14,
        DebtConsolidationLow = 15,
        DebtConsolidationHigh = 16,
        SPLDeclineMedium = 17,
        SPLDeclineLow = 18,
        SPL = 19,
        WingfieldFinanceLow = 20,
        WingfieldFinanceMedium = 21,
        WingfieldFinanceHigh = 22,
        WingfieldFinanceVeryHigh = 23,
        WingfieldReFinanceLow = 24,
        WingfieldReFinanceMedium = 25,
        WingfieldReFinanceHigh = 26,
        WingfieldReFinanceVeryHigh = 27,
        BestPriceForMyCar = 28,
        RentToBuy = 29,
        DebtConsolidationHigh2 = 30,
        DBClient = 31,
        JMCreditlife = 32,
        OobaHomeLoanLow = 34,
        vehicleFinanceCalculatorLow = 35,
        vehicleFinanceCalculatorMedium = 36,
        vehicleFinanceCalculatorHigh = 37,
        vehicleFinanceCalculatorVeryHigh = 38,
        Insights_Secured_Credit = 39,
        Insights_Personal_Loan_High = 40
    }
}
